import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const TRXWalletMonitor = () => {
    const trxAddress = "TV5bqv9a4L8kaBnqgvZvyGVBQVNZVCPiwD"; // Replace with your TRON address
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const fetchWalletData = async () => {
        try {
            const response = await axios.get(`https://apilist.tronscan.org/api/account?address=${trxAddress}`);
            const trxBalance = response.data.balance / 1e6; // Convert from SUN to TRX
            setBalance(trxBalance);

            const txs = response.data.transactions || [];
            setTransactions(txs);
        } catch (error) {
            console.error('Error fetching TRON wallet data:', error);
        }
    };

    useEffect(() => {
        fetchWalletData();
        const interval = setInterval(fetchWalletData, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval);
    }, [trxAddress]);


    // useEffect(()=>{
    //     Swal.fire({text:"Completed",title:"Done",allowOutsideClick:false,icon:"success"})
    // },[])



    return (
        <div style={{ width: '500px', height: '500px', padding: '100px' }}>
            <h3>TRON Wallet Monitor</h3>
            <p>Address: {trxAddress}</p>
            <p>Balance: {balance} TRX</p>
            <h4>Recent Transactions:</h4>
            <ul>
                {transactions.map((tx, index) => (
                    <li key={index}>{tx.txID}</li>
                ))}
            </ul>
        </div>
    );
};

export default TRXWalletMonitor;
