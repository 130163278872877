// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import Swal from "sweetalert2";
// import ClipLoader from 'react-spinners/ClipLoader';  // Optional spinner component
// import styled from 'styled-components';
// import { useParams } from 'react-router-dom';

// // Styled Components
// const VerificationContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background-color: #f4f7fc;
// `;

// const VerificationBox = styled.div`
//   background-color: white;
//   border-radius: 8px;
//   padding: 30px;
//   width: 100%;
//   max-width: 500px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   text-align: center;
// `;

// const LoadingWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;

// const ResultMessage = styled.p`
//   font-size: 1.2rem;
//   color: #333;
//   margin-top: 20px;
//   font-weight: 500;
// `;

// const Title = styled.h2`
//   font-size: 2rem;
//   color: #007bff;
//   margin-bottom: 20px;
// `;

// const AffiliateVerifyEmail = () => {
//     const { token } = useParams(); // Extract token from URL params
//     const [loading, setLoading] = useState(true);
//     const [verificationMessage, setVerificationMessage] = useState("");
  
//     useEffect(() => {
//       const verifyEmail = async () => {
//         try {
//           const response = await axios.get(
//             `https://elexdondigitalacademy.com/api/verify.php?token=${token}`
//           );
  
//           if (response.data.success) {
//             setVerificationMessage(response.data.message);
//             Swal.fire({ icon: "success", text: response.data.message });
//           } else {
//             setVerificationMessage(response.data.error);
//             Swal.fire({ icon: "error", text: response.data.error });
//           }
//         } catch (error) {
//           setVerificationMessage("Error verifying email.");
//           Swal.fire({ icon: "error", text: "Error verifying email." });
//           console.error("Error during verification:", error); // Log error for debugging
//         } finally {
//           setLoading(false);
//         }
//       };
  
//       if (token) verifyEmail();
//     }, [token]);




//     return (
//         <VerificationContainer>
//             <VerificationBox>
//                 <Title>Email Verification</Title>
//                 {loading ? (
//                     <LoadingWrapper>
//                         <ClipLoader size={50} color="#007bff" />
//                         <p>Verifying your email...</p>
//                     </LoadingWrapper>
//                 ) : (
//                     <ResultMessage>{verificationMessage}</ResultMessage>
//                 )}
//             </VerificationBox>
//         </VerificationContainer>
//     );
// };

// export default AffiliateVerifyEmail;



// import React, { useEffect, useState } from "react";
// import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   font-family: Arial, sans-serif;
//   background-color: #f4f4f4;
// `;

// const VerificationBox = styled.div`
//   background-color: #fff;
//   padding: 30px;
//   border-radius: 8px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//   width: 400px;
//   text-align: center;
// `;

// const Heading = styled.h1`
//   font-size: 24px;
//   color: #333;
// `;

// const Message = styled.p`
//   font-size: 16px;
//   color: ${props => (props.success ? "#4caf50" : "#f44336")};
// `;

// const Spinner = styled.div`
//   border: 4px solid #f3f3f3;
//   border-top: 4px solid #3498db;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   animation: spin 2s linear infinite;
//   margin: 20px auto;
  
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
// `;

// const AffiliateVerifyEmail = () => {
//   const [status, setStatus] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     // Get the token from the URL query parameter
//     const urlParams = new URLSearchParams(window.location.search);
//     const token = urlParams.get('token');

//     if (token) {
//       // Call the backend API to verify the email
//       fetch("https://elexdondigitalacademy.com/api/affiliateverify.php", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify({ token })
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.message) {
//             setStatus(data.message);
//             setLoading(false);
//           } else if (data.error) {
//             setErrorMessage(data.error);
//             setLoading(false);
//           }
//         })
//         .catch(() => {
//           setErrorMessage("An error occurred. Please try again later.");
//           setLoading(false);
//         });
//     } else {
//       setErrorMessage("Invalid token.");
//       setLoading(false);
//     }
//   }, []);

//   return (
//     <Container>
//       <VerificationBox>
//         <Heading>Email Verification</Heading>
//         {loading ? (
//           <Spinner />
//         ) : (
//           <Message success={status && !errorMessage}>
//             {status || errorMessage}
//           </Message>
//         )}
//       </VerificationBox>
//     </Container>
//   );
// };

// export default AffiliateVerifyEmail;




// EmailVerification.js
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PaystackPop from "@paystack/inline-js";
import axios from 'axios';
import Swal from 'sweetalert2';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
`;

const VerificationBox = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: rgba(0,0,255,0.5);
`;

const Message = styled.p`
  color: ${({ isError }) => (isError ? 'red' : 'green')};
  font-size: 16px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  margin-bottom:10px;
`

const Button = styled.button`
  background-color: rgba(0,0,255,0.5);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const ErrorBox = styled.div`
  color: red;
  font-weight: bold;
  margin-top: 10px;
`;

const AffiliateVerifyEmail = () => {
    const {token}=useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const verifyEmail = async () => {
    setLoading(true);
    setMessage('');
    setIsError(false);

    try {
      const response = await fetch('https://elexdondigitalacademy.com/api/affiliate_verify_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || 'Your email has been verified successfully!');
        setIsError(false);
        navigate("/affiliatelogin")
        Swal.fire({text: 'Your email has been verified successfully!'})
      } else {
        setMessage(data.error || 'An error occurred.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Something went wrong. Please try again later.');
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  // Trigger verification when the component is mounted
  // React.useEffect(() => {
  //   if (token) {
  //     verifyEmail();
  //   }
  // }, [token]);

  const [formData,setFormData]=useState({email:"",name:""})
    
  const payWithPaystack = () => {
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: "pk_test_60e1f53bba7c80b60029bf611a26a66a9a22d4e4",
    //   key: "pk_live_3626fe7772aaca28a10724ebb1f9727dfcc5d6cb",
      amount: 2000 * 100,
      email: formData.email,
      name: formData.name,
      onSuccess: (transaction) => {

        verifyTransaction(transaction.reference)
        
      },
      onCancel: () => {
        Swal.fire({ icon: "error", text: "Payment cancelled.", showConfirmButton: true });
      },
      onError: (error) => {
        Swal.fire({ icon: "error", text: `Payment failed: ${error.message}`, showConfirmButton: true });
      }
    });
  };

  
  const verifyTransaction = async (reference) => {
    const loadingAlert = Swal.fire({
      title: "Confirming payment",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
  
    Swal.showLoading();
  
    try {
      // Include your secret key in headers
      const response = await axios.get(`https://api.paystack.co/transaction/verify/${reference}`, {
        headers: {
          Authorization: `Bearer sk_test_2c7610bd616abcaeaf6a002ad8d96f994d8ff723`
        //   Authorization: `Bearer sk_live_99d792e7e76bf51ca27d7798bb41d2e3204a656d`
        }
      });

    
  
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          text: "Payment successful!",
          showConfirmButton: true,
          timer: 2000
        });
  
        verifyEmail();
      } else {
        Swal.fire({
          icon: "error",
          text: `${response.data.message}`,
          showConfirmButton: true
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || 'Error verifying payment!',
        showConfirmButton: true
      });
    } finally {
      loadingAlert.close();
    }
  };



  const handlePayment=(e)=>{
    e.preventDefault();
    Swal.fire({text:"You are being directed to make the one time affiliate registration fee of NGN 2000.",
      allowOutsideClick:false,
      showCancelButton:true,
  }).then((result)=>{
      if(result.isConfirmed){
          payWithPaystack(e);
  }

  })
}



  return (
    <Container>
      <VerificationBox>
        <Title>Email Verification</Title>

        <form onSubmit={handlePayment}>
        <Input value={formData.email} type='email' onChange={(e)=>setFormData({...formData, email:e.target.value})} required placeholder='Enter your verified email'/>
       
          <Input value={formData.name} type='text' onChange={(e)=>setFormData({...formData, name:e.target.value})} required placeholder='Enter your name'/>
          <Button type='submit'>Click to complete</Button>
        </form>
        {loading && <Message>Loading...</Message>}
        {message && <Message isError={isError}>{message}</Message>}
        {!loading && !isError && message && (
          <Button onClick={() => window.location.href = '/'}>Go to Dashboard</Button>
        )}
        {isError && <ErrorBox>Something went wrong. Please check your token or try again later.</ErrorBox>}
      </VerificationBox>
      </Container>
  );
};

export default AffiliateVerifyEmail;
