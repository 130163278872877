import React from "react";
import styled from "styled-components";

const AffiliateTermsAndConditions = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Backdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>  
        <Title>Affiliate Program Terms & Conditions</Title>
        <Content>
          <Section>
            <h2>Welcome to ELEXDON DIGITAL ACADEMY Affiliate Program</h2>
            <p>
              By joining our affiliate program, you agree to the following terms and conditions.
              Please read them carefully before registering.
            </p>
          </Section>
          <Section>
            <h3>Eligibility</h3>
            <p>
              To participate in the ELEXDON DIGITAL ACADEMY Affiliate Program, you must:
            </p>
            <ul>
              <li>Be at least 18 years old.</li>
              <li>Provide accurate and complete registration details.</li>
              <li>
                Agree to make a one-time non-refundable registration fee payment of ₦2000.
              </li>
            </ul>
          </Section>
          <Section>
            <h3>Commission and Payouts</h3>
            <p>
              Affiliates will earn 20% commission for every successful sale made using their
              unique referral code.
            </p>
            <ul>
              <li>
                Commissions are calculated based on the total amount of each qualifying sale.
              </li>
              <li>Payouts will be made on a monthly basis.</li>
              <li>
                All payouts are subject to a minimum threshold of ₦5000. If this threshold is
                not met, the earnings will roll over to the next month.
              </li>
            </ul>
          </Section>
          <Section>
            <h3>Responsibilities of the Affiliate</h3>
            <p>
              As an affiliate, you are responsible for:
            </p>
            <ul>
              <li>
                Promoting ELEXDON DIGITAL ACADEMY through ethical and legal means.
              </li>
              <li>Ensuring that your referrals comply with our terms and policies.</li>
              <li>
                Avoiding any form of misleading or spammy practices to drive traffic or sales.
              </li>
            </ul>
          </Section>
          <Section>
            <h3>Termination</h3>
            <p>
              ELEXDON DIGITAL ACADEMY reserves the right to terminate any affiliate's account
              if found in violation of our terms, including but not limited to:
            </p>
            <ul>
              <li>Fraudulent activity.</li>
              <li>Use of unethical marketing methods.</li>
              <li>Providing false or misleading information.</li>
            </ul>
          </Section>
          <Section>
            <h3>Privacy Policy</h3>
            <p>
              At ELEXDON DIGITAL ACADEMY, we are committed to protecting your privacy. Any
              information collected during the registration and program participation will be
              used solely for program administration and payouts.
            </p>
            <p>
              We will not share your information with third parties without your explicit
              consent unless required by law.
            </p>
          </Section>
          <Section>
            <h3>Agreement</h3>
            <p>
              By registering for the ELEXDON DIGITAL ACADEMY Affiliate Program, you agree to
              these terms and conditions and acknowledge that you have made the one-time
              payment of ₦2000. If you disagree with any part of these terms, you must not
              register or participate in the program.
            </p>
          </Section>
          <Section>
            <h3>Contact Us</h3>
            <p>
              For any questions or concerns regarding these terms, please contact us at:
            </p>
            <ul>
              <li>Email: support@elexdondigitalacademy.com</li>
              <li>Phone: +234-818-560-9702</li>
            </ul>
          </Section>
        </Content>
      </ModalContainer>
    </Backdrop>
  );
};

// Styled Components
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90%;
  padding: 20px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Content = styled.div`
  color: #555;
  font-size: 1rem;
  line-height: 1.6;

  ul {
    margin-left: 20px;
    list-style-type: disc;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #007bff;
  }

  p {
    margin-bottom: 10px;
  }
`;

export default AffiliateTermsAndConditions;
