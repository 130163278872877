import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import axios from "axios";
import { clearCart } from "../Features/Slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BTCWalletMonitor from "./BitcoinQuery";

// Styled Components for the Modal
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BtcWallet = styled.p`
  font-size: 1.2rem;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  word-break: break-all;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #ddd;
  }

  &.send {
    background-color: #4caf50;
    color: white;
  }

  &.cancel {
    background-color: #f44336;
    color: white;
  }
`;

const BtcPaymentModal = ({ btcWallet, totalNgnAmount, totalBtcAmount, onClose }) => {
  const [transactionRef, setTransactionRef] = useState("");
  const [confirmTransactionRef,setConfirmTransactionRef]=useState('')
  const deliveryDetails = useSelector((state) => state.deliveryDetails);
  const cart = useSelector((state) => state.cart);
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [initialBalanceInBTC,setInitialBalanceInBTC]=useState(null)

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      return total + price;
    }, 0);
  };



  //fetching pending orders
  const fetchPendingOrders = async (transactionRef,orderSummary) => {
    const loadingAlert = Swal.fire({text:"fetching pending orders..."})
    Swal.showLoading();
    try {
      const response = await axios.get("https://elexdondigitalacademy.com/api/fetch_pending_orders.php");

      if (response.data.status === 'success') {
        // setOrders(response.data.data);
        console.log(response.data.data)
        handleQueryWallet(transactionRef,response.data.data,orderSummary);
      } else {
        console.error('Failed to fetch orders:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }finally{
      loadingAlert.close();
    }
  };


  const fetchInitialBalance = async () => {
    try {
      // Fetch the initial balance
      const response = await axios.get(`https://blockstream.info/api/address/${btcWallet}`);
      const initialBalanceInSats =
        response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
       setInitialBalanceInBTC (initialBalanceInSats / 1e8) // Convert from satoshis to BTC
      console.log(`Initial wallet balance: ${initialBalanceInBTC} BTC`);
      
    } catch (error) {
      console.error("Error fetching initial Bitcoin wallet balance:", error);
      Swal.fire("Error", "Failed to fetch initial Bitcoin wallet balance. Please try again.", "error");
      return false; // Exit if the initial fetch fails
    }
    return true;
  };

useEffect(()=>{
  fetchInitialBalance();
},[])


  const handleQueryWallet = async (transactionRef, data, orderSummary) => {
    let pollingInterval; // To store the interval reference
    // let initialBalanceInBTC = 0;
  
    // const fetchInitialBalance = async () => {
    //   try {
    //     // Fetch the initial balance
    //     const response = await axios.get(`https://blockstream.info/api/address/${btcWallet}`);
    //     const initialBalanceInSats =
    //       response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
    //     initialBalanceInBTC = initialBalanceInSats / 1e8; // Convert from satoshis to BTC
    //     console.log(`Initial wallet balance: ${initialBalanceInBTC} BTC`);
    //   } catch (error) {
    //     console.error("Error fetching initial Bitcoin wallet balance:", error);
    //     Swal.fire("Error", "Failed to fetch initial Bitcoin wallet balance. Please try again.", "error");
    //     return false; // Exit if the initial fetch fails
    //   }
    //   return true;
    // };
  
    const pollWallet = async () => {
      try {
        // Fetch wallet details during polling
        const response = await axios.get(`https://blockstream.info/api/address/${btcWallet}`);
        const balanceInSats =
          response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
        const balanceInBTC = balanceInSats / 1e8; // Convert from satoshis to BTC
  
        console.log(`Current wallet balance: ${balanceInBTC} BTC`);
  
        // Check if the required BTC amount has been received
        if (balanceInBTC - initialBalanceInBTC >= parseFloat(totalBtcAmount)) {
          console.log("Required BTC amount received.");
          clearInterval(pollingInterval); // Stop polling
          filterThePendingOrder(transactionRef, data, orderSummary); // Process the pending order
        } else {
          console.log("BTC payment not yet received.");
        }
      } catch (error) {
        console.error("Error querying Bitcoin wallet during polling:", error);
        Swal.fire("Error", "Failed to query Bitcoin wallet during polling. Please try again.", "error");
        clearInterval(pollingInterval); // Stop polling if there's an error
      }
    };
  
    // Fetch the initial balance
    const isInitialized = await fetchInitialBalance();
    if (!isInitialized) return; // Exit if the initial fetch fails
  
    // Start polling every 3 seconds
    pollingInterval = setInterval(pollWallet, 3000);
  
    // Run the first poll immediately
    pollWallet();
  };
  


  
  // const [theOrder,setTheOrder]=useState()
  

  const filterThePendingOrder = (transactionRef,data,orderSummary)=>{
    console.log(data)
      const theOrder = data.find((order)=>order.transaction_reference===transactionRef)
      // setTheOrder(theOrder)
      handleApprove(theOrder,orderSummary)
      console.log(theOrder);
  }

  





  

  const sendOrderEmailFunction2 = async (orderSummary) => {
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };
    console.log(orderSummary)


    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/send_order_email.php", {
        buyerEmail: orderSummary.email,
        sellerEmail: 'info@elexdontech.com',
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Order Approved" });
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
      console.error(error)
    } finally {
      loadingAlert.close();
    }
  };


  // Handle approving an order
  const handleApprove = async (theOrder,orderSummary) => {
    // Confirmation dialog
   

      Swal.fire({
        title: 'Processing...',
        text: 'Your request is being processed!',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      console.log(theOrder)

      try {
        const response = await axios.post("https://elexdondigitalacademy.com/api/approve_order.php", { order_id: theOrder.id });

        if (response.data.status === 'success') {
          Swal.fire('Approved!', 'The order has been approved.', 'success');
   
        sendOrderEmailFunction2(orderSummary)
        } else {
          Swal.fire('Error!', response.data.message, 'error');
          console.error('Failed to approve order:', response.data.message);
        }
      } catch (error) {
        Swal.fire('Error!', 'An error occurred while approving the order.', 'error');
        console.error('Error approving order:', error);
      }
    
  }




  const postOrderFunction = async () => {
    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: transactionRef,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => ({
        id: item.id,
        title: `${item.title} by ${item.author} - Course ID:${item.id}`,
        price: item.price
      })),
      total: calculateTotal(),
      currency: "NGN"
    };

    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/save_pending_order.php", orderSummary);
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: response.data.message,
        allowOutsideClick:false
      });

      dispatch(clearCart());
      navigate("/userdashboard");//kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
      //call function to query the wallet here
    //   setActivePage('courses');
    fetchPendingOrders(orderSummary.transactionRef,orderSummary);
    // handleQueryWallet(orderSummary)

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: error.response?.data?.error || 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  };


  const sendOrderEmailFunction = async () => {
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: transactionRef,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => `${item.title} by ${item.author} Course ID:${item.id} - ₦${new Intl.NumberFormat().format(item.price)}`),
      total: `₦${new Intl.NumberFormat().format(calculateTotal())}`
    };

    try {
      const response = await axios.post("https://elexdondigitalacademy.com/api/send_pending_order_email.php", {
        buyerEmail: deliveryDetails.email,
        sellerEmail: 'info@elexdontech.com',
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Thank you for your order. Your payment is being confirmed and your order shall appear in your dashboard as soon as your payment is confirmed",allowOutsideClick:false});
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    } finally {
      loadingAlert.close();
    }
  };


  const handleOrder = ()=>{
    if (!transactionRef) {
        Swal.fire({ icon: "warning", text: "Please enter the transaction reference." });
        return;
      }else if(transactionRef!==confirmTransactionRef){
        Swal.fire({ icon: "warning", text: "Transaction Reference does not match." });
        return;
      }
    postOrderFunction();
    sendOrderEmailFunction();

}




  return (
    <ModalBackground>
      <ModalWrapper>
        <h2>BTC Payment</h2>
        <p>Please transfer the BTC equivalent of your total to the following wallet, enter the transaction refrence after payment and then click the send button:</p>

        {/* Displaying BTC Wallet Address */}
        <BtcWallet>BTC wallet Address: <br/>{btcWallet}</BtcWallet>

        {/* Displaying Amounts in NGN and BTC */}
        <p>Total Amount in NGN: <strong>₦{totalNgnAmount.toLocaleString()}</strong></p>
        <p>Total Amount in BTC: <strong>{totalBtcAmount} BTC</strong></p>

        {/* Input for transaction reference */}
       
        <Input
          type="text"
          placeholder="Enter your btc payment transaction reference"
          value={transactionRef}
          onChange={(e) => setTransactionRef(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Enter your btc payment transaction reference again"
          value={confirmTransactionRef}
          onChange={(e) => setConfirmTransactionRef(e.target.value)}
        />

        {/* Buttons: Send & Cancel */}
        <ButtonGroup>
          <Button className="send" onClick={handleOrder}>Send</Button>
          <Button className="cancel" onClick={onClose}>Cancel</Button>
        </ButtonGroup>
        
      </ModalWrapper>
      
    </ModalBackground>
  );
};

export default BtcPaymentModal;





// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import Swal from "sweetalert2";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// import { clearCart } from "../Features/Slice";
// import { useNavigate } from "react-router-dom";

// // Styled Components for the Modal
// const ModalBackground = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.7);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 10;
// `;

// const ModalWrapper = styled.div`
//   background-color: white;
//   width: 90%;
//   max-width: 500px;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
//   text-align: center;
//   animation: fadeIn 0.3s ease-in-out;
//   @keyframes fadeIn {
//     from {
//       opacity: 0;
//     }
//     to {
//       opacity: 1;
//     }
//   }
// `;

// const BtcWallet = styled.p`
//   font-size: 1.2rem;
//   background-color: #f5f5f5;
//   padding: 10px;
//   border-radius: 5px;
//   word-break: break-all;
//   margin-bottom: 20px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   font-size: 1rem;
//   margin: 15px 0;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   font-size: 1rem;
//   cursor: pointer;
//   transition: background-color 0.3s;
  
//   &:hover {
//     background-color: #ddd;
//   }

//   &.send {
//     background-color: #4caf50;
//     color: white;
//   }

//   &.cancel {
//     background-color: #f44336;
//     color: white;
//   }
// `;

// const BtcPaymentModal = ({ btcWallet, totalNgnAmount, totalBtcAmount, onClose }) => {
//   const [transactionRef, setTransactionRef] = useState("");
//   const [confirmTransactionRef, setConfirmTransactionRef] = useState("");
//   const [initialBalanceInBTC, setInitialBalanceInBTC] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const deliveryDetails = useSelector((state) => state.deliveryDetails);
//   const cart = useSelector((state) => state.cart);
//   const userInfo = useSelector((state) => state.userInfo);

//   useEffect(() => {
//     // Fetch the initial wallet balance when the modal opens
//     const fetchInitialBalance = async () => {
//       try {
//         const response = await axios.get(`https://blockstream.info/api/address/${btcWallet}`);
//         const balanceInSats =
//           response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
//         const balanceInBTC = balanceInSats / 1e8;
//         setInitialBalanceInBTC(balanceInBTC);
//         console.log(`Initial wallet balance: ${balanceInBTC} BTC`);
//       } catch (error) {
//         console.error("Error fetching initial wallet balance:", error);
//         Swal.fire("Error", "Failed to fetch initial wallet balance. Please try again.", "error");
//       }
//     };

//     fetchInitialBalance();
//   }, [btcWallet]);

//   const calculateTotal = () => {
//     return cart.reduce((total, item) => {
//       const price = parseFloat(item.price) || 0;
//       return total + price;
//     }, 0);
//   };

//   const pollWalletForPayment = async (orderSummary) => {
//     if (initialBalanceInBTC === null) {
//       Swal.fire("Error", "Initial wallet balance not fetched. Please try again.", "error");
//       return;
//     }

//     let pollingInterval;
//     const pollWallet = async () => {
//       try {
//         const response = await axios.get(`https://blockstream.info/api/address/${btcWallet}`);
//         const balanceInSats =
//           response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
//         const balanceInBTC = balanceInSats / 1e8;

//         console.log(`Current wallet balance: ${balanceInBTC} BTC`);
//         if (balanceInBTC - initialBalanceInBTC >= parseFloat(totalBtcAmount)) {
//           console.log("Payment received!");
//           clearInterval(pollingInterval);
//           handleOrderApproval(orderSummary);
//         } else {
//           console.log("Payment not yet received.");
//         }
//       } catch (error) {
//         console.error("Error polling wallet:", error);
//         Swal.fire("Error", "Failed to poll wallet for payment. Please try again.", "error");
//         clearInterval(pollingInterval);
//       }
//     };

//     pollingInterval = setInterval(pollWallet, 3000);
//   };

//   const handleOrderApproval = async (orderSummary) => {
//     try {
//       const response = await axios.post("https://elexdondigitalacademy.com/api/approve_order.php", {
//         order_id: orderSummary.transactionRef,
//       });

//       if (response.data.status === "success") {
//         Swal.fire("Success", "Payment confirmed and order approved!", "success");
//         navigate("/userdashboard");
//         dispatch(clearCart());
//       } else {
//         Swal.fire("Error", "Failed to approve order. Please contact support.", "error");
//       }
//     } catch (error) {
//       console.error("Error approving order:", error);
//       Swal.fire("Error", "Failed to approve order. Please try again later.", "error");
//     }
//   };

//   const handleOrder = async () => {
//     if (!transactionRef || transactionRef !== confirmTransactionRef) {
//       Swal.fire("Error", "Transaction references do not match.", "error");
//       return;
//     }

//     const orderSummary = {
//       userId: userInfo.id,
//       transactionRef,
//       name: deliveryDetails.name,
//       email: deliveryDetails.email,
//       cartItems: cart.map((item) => ({
//         title: item.title,
//         price: item.price,
//       })),
//       total: calculateTotal(),
//       totalBtcAmount,
//     };

//     try {
//       Swal.fire({ text: "Placing order...", allowOutsideClick: false });
//       Swal.showLoading();
//       await axios.post("https://elexdondigitalacademy.com/api/save_pending_order.php", orderSummary);
//       Swal.fire("Success", "Order placed. Monitoring payment...", "success");
//       pollWalletForPayment(orderSummary);
//     } catch (error) {
//       console.error("Error placing order:", error);
//       Swal.fire("Error", "Failed to place order. Please try again.", "error");
//     }
//   };

//   return (
//     <ModalBackground>
//       <ModalWrapper>
//         <h2>BTC Payment</h2>
//         <p>Send the BTC equivalent of your order to the wallet below and provide your transaction reference:</p>
//         <BtcWallet>{btcWallet}</BtcWallet>
//         <p>Total in NGN: ₦{totalNgnAmount.toLocaleString()}</p>
//         <p>Total in BTC: {totalBtcAmount}</p>
//         <Input
//           type="text"
//           placeholder="Transaction Reference"
//           value={transactionRef}
//           onChange={(e) => setTransactionRef(e.target.value)}
//         />
//         <Input
//           type="text"
//           placeholder="Confirm Transaction Reference"
//           value={confirmTransactionRef}
//           onChange={(e) => setConfirmTransactionRef(e.target.value)}
//         />
//         <ButtonGroup>
//           <Button className="send" onClick={handleOrder}>
//             Send
//           </Button>
//           <Button className="cancel" onClick={onClose}>
//             Cancel
//           </Button>
//         </ButtonGroup>
//       </ModalWrapper>
//     </ModalBackground>
//   );
// };

// export default BtcPaymentModal;

