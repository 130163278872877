

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaMoneyBill, FaRegCalendarAlt, FaShoppingCart } from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useSelector } from "react-redux";

const AffiliateAccountPage = () => {

  // State
  const affiliateInfo = useSelector(state=>state.affiliateInfo);
  const referralCode = affiliateInfo.referralCode 
  console.log(affiliateInfo)

  const calculateNextSettlementDate = () => {
    const today = new Date();
    const nextDate = new Date(today);
    nextDate.setMonth(today.getMonth() + 1); // Move to next month
    return nextDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };


  const [affiliateData, setAffiliateData] = useState({
    numberOfSales: 0,
    totalAffiliateAmount: 0,
    amountSettled: 0,
    amountToBeSettled: 0,
    nextSettlementDate: calculateNextSettlementDate(),
  });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch Orders by Referrer Code
  useEffect(() => {
    if (referralCode) {
      fetchOrders(referralCode);
    }
  }, [referralCode]);

  const fetchOrders = async (referralCode) => {
    try {
      const response = await fetch("https://elexdondigitalacademy.com/api/fetch_orders_by_referrer_code.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ referrer_code: referralCode }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch orders.");
      }

      const data = await response.json();

      if (Array.isArray(data)) {
        setOrders(data);
        // Calculate affiliate details from orders
        calculateAffiliateData(data);
      } else {
        setError("No orders found.");
      }
    } catch (err) {
      setError(err.message);  
    } finally {
      setLoading(false);
    }
  };

  const calculateAffiliateData = (orders) => {
    const totalSales = orders.length;
    const totalAmount = orders.reduce((sum, order) => sum + (order.total_amount*20/100 || 0), 0);

    setAffiliateData((prevState) => ({
      ...prevState,
      numberOfSales: totalSales,
      totalAffiliateAmount: totalAmount,
      amountToBeSettled: totalAmount - prevState.amountSettled, // Adjust as needed
    }));
  };

 

  return (
    <Container>
      <Title>Affiliate Accounts</Title>
      <CardsContainer>
        <Card>
          <IconContainer>
            <FaShoppingCart size={30} />
          </IconContainer>
          <CardText>
            <h3>Number of Sales</h3>
            <p>{affiliateData.numberOfSales}</p>
          </CardText>
        </Card>
        <Card>
          <IconContainer>
            <FaMoneyBill size={30} />
          </IconContainer>
          <CardText>
            <h3>Total Affiliate Amount</h3>
            <p>₦{affiliateData.totalAffiliateAmount}</p>
          </CardText>
        </Card>
        <Card>  
          <IconContainer>
            <MdAccountBalanceWallet size={30} />
          </IconContainer>
          <CardText>
            <h3>Amount Settled</h3>
            <p>₦{affiliateData.amountSettled}</p>
          </CardText>
        </Card>
        <Card>
          <IconContainer>
            <MdAccountBalanceWallet size={30} />
          </IconContainer>
          <CardText>
            <h3>Amount to be Settled</h3>
            <p>₦{affiliateData.amountToBeSettled}</p>
          </CardText>
        </Card>
        <Card>
          <IconContainer>
            <FaRegCalendarAlt size={30} />
          </IconContainer>
          <CardText>
            <h3>Next Settlement Date</h3>
            <p>{affiliateData.nextSettlementDate}</p>
          </CardText>
        </Card>
      </CardsContainer>

      <OrdersContainer>
        <OrdersTitle>Sales</OrdersTitle>
        {loading ? (
          <p>Loading sales...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : orders.length > 0 ? (
          <OrdersList>
            {orders.map((order) => (
              <OrderItem key={order.id}>
                <p>
                  <strong>Date:</strong> {order.order_date}
                </p>
                <p>
                  <strong>Transaction Ref:</strong> {order.transaction_reference}
                </p>
                <p>
                  <strong>Name:</strong> {order.name}
                </p>
                <p>
                  <strong>Email:</strong> {order.email}
                </p>
                {/* <p>
                  <strong>Total Amount:</strong> ₦{order.total_amount}
                </p> */}
              </OrderItem>
            ))}
          </OrdersList>
        ) : (
          <p>No sales found.</p>
        )}
      </OrdersContainer>
    </Container>
  );
};

// Styled Components remain the same as above

// Styled Components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: rgba(0,0,255,0.6);
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 10px;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const IconContainer = styled.div`
  margin-bottom: 15px;
  color: #007bff;
`;

const CardText = styled.div`
  h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: #666;
    font-weight: bold;
  }
`;

const OrdersContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const OrdersTitle = styled.h2`
  font-size: 1.5rem;
  color: rgba(0,0,255,0.5);
  margin-bottom: 20px;
`;

const OrdersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const OrderItem = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;

  p {
    margin: 5px 0;
    font-size: 0.9rem;

    strong {
      font-weight: bold;
    }
  }
`;

export default AffiliateAccountPage;

