

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const BTCWalletMonitor = () => {
    // const btcAddress = "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa"; //1st btc wallet address
    const btcAddress = "1EPvkK3ANxZNtR8Ky3FUnuBULvzrZDKpBL"; //elexdon btc wallet
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const isValidBTCAddress = (address) => {
        return /^([13]|bc1)[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);
    };

    const fetchWalletData = async () => {
        if (!isValidBTCAddress(btcAddress)) {
            Swal.fire('Invalid Bitcoin Address', 'Please check the address and try again.', 'error');
            return;
        }

        try {
            const response = await axios.get(`https://blockstream.info/api/address/${btcAddress}`);
            const balanceInSats = response.data.chain_stats.funded_txo_sum - response.data.chain_stats.spent_txo_sum;
            setBalance(balanceInSats / 1e8); // Convert from satoshis to BTC
            const txs = response.data.txids || [];
            setTransactions(txs);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching wallet data:', error);
            Swal.fire('Error', 'Failed to fetch wallet data. Check the console for details.', 'error');
        }
    };

    useEffect(() => {
        fetchWalletData();
        const interval = setInterval(fetchWalletData, 5000);
        return () => clearInterval(interval);
    }, [btcAddress]);

    return (
        <div style={{ width: '500px', height: '500px', padding: '100px' }}>
            <h3>BTC Wallet Monitor</h3>
            <p>Address: {btcAddress}</p>
            <p>Balance: {balance} BTC</p>
            <h4>Recent Transactions:</h4>
            <ul>
                {transactions.map((tx, index) => (
                    <li key={index}>{tx}</li>
                ))}
            </ul>
        </div>
    );
};

export default BTCWalletMonitor;

