// import React, { useContext, useState } from 'react';
// import '../CSS/InstructorRegistrationForm.css';
// import axios from 'axios';
// import Swal from "sweetalert2";
// import { css } from '@emotion/react';
// import ClipLoader from 'react-spinners/ClipLoader';
// import { Context } from './Context';
// import { FaBackward } from 'react-icons/fa6';

// const AffiliateRegistrationForm = () => {
//     const { affiliateRegistrationUrl } = useContext(Context);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         phone: '',
//         bankAccount: '',
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: name === 'email' ? value.toLowerCase() : value,
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsSubmitting(true);
//         try {
//             const response = await axios.post(`${affiliateRegistrationUrl}`, formData);
//             console.log('Form submitted successfully:', response.data);
            
//             if (response.data.status === 'success') {
//                 Swal.fire({
//                     icon: "success",
//                     text: response.data.message
//                 });
//                 setFormData({
//                     firstName: '',
//                     lastName: '',
//                     email: '',
//                     phone: '',
//                     bankAccount: '',
//                 });
//             } else {
//                 Swal.fire({
//                     icon: "error",
//                     text: response.data.message || 'An error occurred'
//                 });
//             }
//         } catch (error) {
//             console.error('Error submitting form:', error);
//             Swal.fire({
//                 icon: "error",
//                 text: error.response ? error.response.data.message : 'Error submitting form'
//             });
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     const override = css`
//         display: block;
//         margin: 0 auto;
//         border-color: red;
//     `;

//     return (
//         <div className="registration-form-container">
//             <h1 className="form-title">Affiliate Registration</h1>
//             <form className="registration-form" onSubmit={handleSubmit}>
//                 <div className="form-groupInst">
//                     <label htmlFor="firstName">First Name</label>
//                     <input
//                         type="text"
//                         id="firstName"
//                         name="firstName"
//                         value={formData.firstName}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <div className="form-groupInst">
//                     <label htmlFor="lastName">Last Name</label>
//                     <input
//                         type="text"
//                         id="lastName"
//                         name="lastName"
//                         value={formData.lastName}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <div className="form-groupInst">
//                     <label htmlFor="email">Email</label>
//                     <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <div className="form-groupInst">
//                     <label htmlFor="phone">Phone Number</label>
//                     <input
//                         type="tel"
//                         id="phone"
//                         name="phone"
//                         value={formData.phone}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <div className="form-groupInst">
//                     <label htmlFor="bankAccount">Bank Account Detail</label>
//                     <textarea
//                         id="bankAccount"
//                         name="bankAccount"
//                         value={formData.bankAccount}
//                         onChange={handleChange}
//                         rows="4"
//                         placeholder='Enter your Account Name, Account Number, and Bank Name'
//                         required
//                     />
//                 </div>
//                 <button type="submit" disabled={isSubmitting} className='submit-button'>
//                     {isSubmitting ? (
//                         <ClipLoader color={"#ffffff"} loading={isSubmitting} css={override} size={15} />
//                     ) : (
//                         "Submit"
//                     )}
//                 </button>
//             </form>
//             <div className='CustomBackButtonWrap'>
//                 <button onClick={() => window.history.back()}><FaBackward /> Back</button>
//             </div>
//         </div>
//     );
// };

// export default AffiliateRegistrationForm;




import React, { useContext, useState } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import ClipLoader from 'react-spinners/ClipLoader';
import { Context } from './Context';
import { FaBackward } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import AffiliateTermsAndConditions from './AffiliateTermsAndConditions';
import PaystackPop from "@paystack/inline-js";

const AffiliateRegistrationForm = () => {
    const [isOpen,setIsOpen]=useState(false);
    // const [onClose,setOnClose]=useState(false);
    const navigate = useNavigate();
    const { affiliateRegistrationUrl2 } = useContext(Context);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        bankName: '',
        bankAccountNumber: '',
        bankAccountName: '',
        country: '',
        state: '',
        address: '',
        password: '',
        confirmPassword: '',
        referralCode:Math.floor(Math.random()*1000000)
    });

    console.log(formData)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            Swal.fire({ icon: "error", text: "Passwords do not match!" });
            return;
        }
     
       const loadingAlert = Swal.fire({text:"Creating your affiliate account..."})
       Swal.showLoading();

        setIsSubmitting(true);
        try {
            const response = await axios.post(affiliateRegistrationUrl2, formData);
            if (response.data.success) {
                Swal.fire({ icon: "success", text: response.data.message });
                navigate("/affiliatelogin")
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    bankName: '',
                    bankAccountNumber: '',
                    bankAccountName: '',
                    country: '',
                    state: '',
                    address: '',
                    password: '',
                    confirmPassword: ''
                });
            } else {
                Swal.fire({ icon: "error", text: response.data.error });
            }
        } catch (error) {
            Swal.fire({ icon: "error", text: "Error submitting form" });
        } finally {
            setIsSubmitting(false);
            loadingAlert.close();
        }
    };


   



    return (
        <div className="registration-form-container">
            <h1 className="form-title">Affiliate Registration</h1>
            <form onSubmit={handleSubmit} >
                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" required />
                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" required />
                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" required />
                <input type="text" name="bankName" value={formData.bankName} onChange={handleChange} placeholder="Bank Name" required />
                <input type="text" name="bankAccountNumber" value={formData.bankAccountNumber} onChange={handleChange} placeholder="Bank Account Number" required />
                <input type="text" name="bankAccountName" value={formData.bankAccountName} onChange={handleChange} placeholder="Bank Account Name" required />
                <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Country" required />
                <input type="text" name="state" value={formData.state} onChange={handleChange} placeholder="State" required />
                <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
                <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />
                <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="Confirm Password" required />
                
                <p style={{
                    marginTop:"10px",
                }}>Check the box below and agree to our affiliate terms and conditions and privacy policy.</p>
                
                <div style={{display:"flex",
                    justifyContent:"flexStart",
                    alignItems:"center",
                    // width:"100%",
                    // backgroundColor:"red",   
                    marginTop:"10px"}}>

                <input type="checkbox" required style={{width:"10%"}} /> <p style={{textAlign:"left"}}>I agree to terms and Conditions and privacy policy.</p>
                </div>

                <p style={{
                    color:"blue",
                    marginTop:"10px",
                    cursor:"pointer",
                    textDecoration:"underline"
                }}
                onClick={()=>setIsOpen(true)}
                >Read terms and conditions</p>
                
                <button type="submit" disabled={isSubmitting} className='submit-button'>
                    {isSubmitting ? <ClipLoader size={15} /> : "Submit"}
                </button>
                <p style={{textDecoration:"underline",fontSize:"0.7",marginTop:"10px",color:"blue",cursor:"pointer"}} onClick={()=>navigate("/affiliatelogin")}>Already have an affiliate account? Login</p>
            </form>
            
            <div className='CustomBackButtonWrap'>
            <button onClick={() => window.history.back()}><FaBackward /> Back</button>
             </div>
             <AffiliateTermsAndConditions isOpen={isOpen} onClose={()=>setIsOpen(false)} />
        </div>
    );
};

export default AffiliateRegistrationForm;
