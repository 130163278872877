
import React, { useContext,useEffect, useState } from 'react'
import LoadingUI from './LoadingUI'
import Header from './Header'
import { Context } from './Context'
import Hero from './Hero'
import LatestCourses from './LatestCourses'
import Footer from './Footer'
import ManualScrollToTop from './ManualScrollToTop'
import Featrures from './Featrures'
import Categories from './Categories'
import Testimonials from './Testimonials'
import Blogs from './Blogs'
import "../CSS/LandingPage.css"
import AboutUs from './AboutUs'
import HowItWorks from './HowItWorks'
import PartnerLogo from "../Images/partnerPic1.png"
import { useNavigate } from 'react-router-dom'
import BecomeInstructor from './BecomeInstructorComponent'
import BecomeAffiliate from './BecomeAffiliateComponent'
import YoutubeFollow from './YoutubeFollow'
import { useLocation } from 'react-router-dom'
import SellYourCourse from './SellYourCourse'


const instructors = [
    { name: "John Doe", bio: "Web Development Expert", image: "https://via.placeholder.com/150" },
    { name: "Jane Smith", bio: "UI/UX Specialist", image: "https://via.placeholder.com/150" },
    // Add more instructors as needed
];

const faqs = [
    { question: "What is the duration of the courses?", answer: "Most courses last between 4 to 12 weeks." },
    { question: "Do you provide certificates?", answer: "Yes, certificates are provided upon successful completion of the course." },
    { question: "Are the courses self-paced?", answer: "Yes, our courses are designed to be self-paced so you can learn at your own convenience." },
    { question: "What if I have questions during the course?", answer: "You can reach out to our instructors via the course forum or through email for any questions or clarifications." },
    { question: "Do I need any prior experience?", answer: "No prior experience is required for most courses, but some advanced courses may have prerequisites." },
    { question: "How can I access the course materials?", answer: "Once you enroll, you will receive guidance on how you can access the course materials through our online learning platform." }
];



const LandingPage = () => {
  const {loading,setLoading,test}=useContext(Context)
  const navigate = useNavigate()
 const location = useLocation()

 const [reRender,setRerender]=useState(true)
 console.log(reRender, "rerender")

 useEffect(()=>{

    const interalId = setTimeout(()=>{
        setRerender(true)
    },100)

    const interalId2 = setTimeout(()=>{
        setRerender(true)
    },1000)

    return ()=>{clearInterval(interalId) ; clearInterval(interalId2)}

  },[])


    return (
        reRender&&<div className="landing-page">
          <Hero/>
       <Featrures/>
         <LatestCourses/>
         
         <Categories/>         
         <Testimonials/>
         <HowItWorks/>
         <Blogs/>
        <AboutUs/>
        
         {loading&&<LoadingUI/>}
           
            <section className="call-to-action">
                <h2>Join Us Today</h2>
                <br/>
                <button onClick={()=>navigate("/signup")}>Sign Up Now</button>
            </section>

            <section className="faq">
                <h2>Frequently Asked Questions</h2>
                <br/>
                <div className="faq-container">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <h3>{faq.question}</h3>
                            <p>{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="partners">
                <h2>Our Partners</h2>
                <div className="partners-logos">
                    <img src={PartnerLogo} alt="PartnerLogo"/>
                </div>
            </section>
                    <SellYourCourse/>
                    {/* <BecomeInstructor/> */}
                    <BecomeAffiliate/>
                    <YoutubeFollow/>
        </div>
    );
};

export default LandingPage;
