import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { affiliateLogin } from "../Features/Slice";


const LoginWrap = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-top:100px;
    padding-bottom:100px;
`

const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const AffiliateLogin = () => {
    const dispatch=useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate=useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const loadingAlert = Swal.fire({text:"Please Wait..."})
    Swal.showLoading();

    const response = await fetch("https://elexdondigitalacademy.com/api/affiliate_login.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    if (data.success) {
        loadingAlert.close();
      Swal.fire({text:"Login Successful",timer:2000})
      navigate('/affiliatedashboard')
      console.log(data)

      const affiliateInfo = data.affiliate;
          const affiliateToken = data.token;
          dispatch(affiliateLogin({ affiliateInfo, affiliateToken }));

    } else {
      setError(data.error);
      loadingAlert.close();
    }
  };

  return (
    <LoginWrap>
        <LoginContainer>
      <h2 style={{color:"rgba(0,0,255,0.5)",textAlign:"center"}}>Affiliate Login</h2>
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit">Login</Button>
        <p style={{textDecoration:"underline",fontSize:"0.7",marginTop:"10px",color:"blue",cursor:"pointer"}} onClick={()=>navigate("/affiliateregistrationform")}>Don't have an affiliate account? Sign up</p>
           
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </LoginContainer>
    </LoginWrap>
    
  );
};

export default AffiliateLogin;
