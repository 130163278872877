
import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../CSS/AdminProfile.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { updateAffiliateInfo } from '../Features/Slice';
import { Context } from './Context';

const AffiliateProfile = () => {
  const affiliateInfo = useSelector((state) => state.affiliateInfo);
  console.log(affiliateInfo)
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [phone, setPhone] = useState('');
  const {AuthorUpdatePhoneUrl}=useContext(Context);
  const primaryUrl = window.location.origin;


  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingAlert = Swal.fire({title:"Updating phone number..."})
    Swal.showLoading()
    try {
      const response = await axios.post(AuthorUpdatePhoneUrl, {
        email: affiliateInfo.email, // Assuming email is stored in adminInfo
        phoneNumber: phone
      });

      if (response.data.success) {
        // Dispatch action to update Redux state
        dispatch(updateAffiliateInfo({ phoneNumber: response.data.author.phone_number }));

        Swal.fire({
          icon: 'success',
          title: 'Update Successful',
          text: 'Phone number updated successfully.',
          confirmButtonText: 'OK'
        });
        setPhone(''); // Clear input field
        setEditModal(false); // Close the modal
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Update Failed',
          text: response.data.error,
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error updating phone number:', error);
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'An error occurred. Please try again.',
        confirmButtonText: 'OK'
      });
    }finally{
      loadingAlert.close()
    }
  };

  return (
    <div className="admin-profile-page">
      <h2>Affiliate Information</h2>
      <p><strong style={{color:"#003366"}}>First Name:</strong> {affiliateInfo.firstName} </p>
      <p><strong style={{color:"#003366"}}>Last Name:</strong> {affiliateInfo.lastName} </p>
      <p><strong style={{color:"#003366"}}>Email:</strong> {affiliateInfo.email}</p>
      <p><strong style={{color:"#003366"}}>Phone:</strong> {affiliateInfo.phone}</p>
      <p><strong style={{color:"#003366"}}>Referral Code:</strong> {affiliateInfo.referralCode}</p>
      
      <p><strong style={{color:"#003366"}}>Referral Link:</strong> {primaryUrl}/ref/{affiliateInfo.referralCode}</p>
      <p>Promote your referral code and referral link and get paid for every successful order via your referral code</p>
      <p><strong style={{color:"#003366"}}>Bank Name:</strong> {affiliateInfo.bankName}</p>
      <p><strong style={{color:"#003366"}}>Account Name:</strong> {affiliateInfo.bankAccountName}</p>
      <p><strong style={{color:"#003366"}}>Account Number:</strong> {affiliateInfo.bankAccountNumber}</p>
      <button onClick={() => setEditModal(!editModal)}>Edit Phone number</button>
      {editModal && (
        <div className='admin-profile-edit-modal'>
          <form onSubmit={handleSubmit}>
            <input 
              placeholder='New Phone Number' 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)} 
              required
            />
            <button type="submit">Update</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AffiliateProfile;

