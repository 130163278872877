import React, { useState, useEffect } from 'react';
import '../CSS/BecomeInstructorPage.css';
import AffiliateImg from "../Images/affiliateImg1.png"
// import IntstructorImg2 from "../Images/instructorImg2.png"
// import InstructorImg3 from "../Images/instructorImg3.png"
import { FaBackward } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

const BecomeAffiliatePage = () => {
    const location = useLocation();
    const navigate= useNavigate()
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    // Function to handle clicks outside the modal
    const handleOutsideClick = (e, setShowModal) => {
        if (e.target.classList.contains('modal-overlay')) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        // Add event listener for closing modals on escape key press
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setShowTerms(false);
                setShowPrivacy(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='BecomeAffiliatePage' key={location.pathname}>
            <div className="become-instructor-page" >
            <h1 className="page-title">Affiliates</h1>
            <div className="content-section">
                {/* <img src={AffiliateImg} alt="Instructor Banner" className="banner-image" /> */}
                <p>
    Join us as an affiliate and start earning extra income for your self

</p>
<p>
    Just Click “Register Here” below to get started.
</p>
{/* <p>Already an Affliate? <span style={{color:"blue",fontWeight:"bold",textDecoration:"underline",cursor:"pointer"}}>Login as Affiliate</span></p> */}

                <br/>
                <p style={{textAlign:"center"}}>Click to read our<br/> <span className="link" onClick={() => setShowTerms(true)}>Affiliate Terms and Conditions</span>  and  <span className="link" onClick={() => setShowPrivacy(true)}>Affiliate Privacy Policy</span>.</p>
                <button className="apply-button" onClick={()=>navigate("/affiliateregistrationform")}>Register Here</button>
                <button className="apply-button" onClick={()=>navigate("/affiliatelogin")}>Already an Affiliate? Login</button>
            </div>

            {/* Modal for Terms and Conditions */}
            {showTerms && (
                <div className="modal-overlay" onClick={(e) => handleOutsideClick(e, setShowTerms)}>
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowTerms(false)}>&times;</span>
                        <h2>Affiliate Terms and Conditions</h2>
                        <p>
                            {/* Terms and conditions content goes here */}
                            <br/>
                            1. Acceptance of Terms
By joining the Elexdon Digital Academy Affiliate Program, you agree to comply with these Terms and Conditions. This agreement constitutes a legally binding contract between you and Elexdon Digital Academy.
<br/><br/>
2. Affiliate Enrollment
To become an affiliate, you must complete the application process and be approved by Elexdon Digital Academy. We reserve the right to accept or reject any application at our discretion.
<br/><br/>
3. Commission Structure
Affiliates earn a commission on qualifying purchases made through their unique affiliate links. The commission rate is subject to change and will be communicated to affiliates in advance.
<br/><br/>
4. Payment
Commissions are paid monthly via the selected payment method, provided the affiliate reaches the minimum payout threshold. Elexdon Digital Academy reserves the right to withhold commissions for any fraudulent or suspicious activity.
<br/><br/>
5. Promotion and Advertising
Affiliates may promote Elexdon Digital Academy courses using approved marketing materials. Affiliates must not engage in any misleading or deceptive advertising practices. Unauthorized use of Elexdon Digital Academy’s trademarks, logos, or other intellectual property is prohibited.
<br/><br/>
6. Termination
Either party may terminate this agreement at any time with written notice. Upon termination, affiliates must cease using all promotional materials and remove all affiliate links from their platforms.
<br/><br/>
7. Limitation of Liability
Elexdon Digital Academy will not be liable for any damages arising out of or related to the affiliate program, including loss of revenue or data.
<br/><br/>
8. Modifications
Elexdon Digital Academy reserves the right to modify these Terms and Conditions at any time. Affiliates will be notified of any changes, and continued participation in the program constitutes acceptance of the new terms.</p>
                    </div>
                </div>
            )}

            {/* Modal for Privacy Policy */}
            {showPrivacy && (
                <div className="modal-overlay" onClick={(e) => handleOutsideClick(e, setShowPrivacy)}>
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowPrivacy(false)}>&times;</span>
                        <h2>Affiliate Privacy Policy</h2>
                        <p>
                            {/* Privacy policy content goes here */}
                            1. Introduction
Elexdon Digital Academy values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information in relation to the Affiliate Program.
<br/><br/>
2. Information Collection
We collect personal information from affiliates during the application process, including name, email address, payment information, and other relevant details. This information is used to manage your participation in the affiliate program and process payments.
<br/><br/>
3. Use of Information
The personal information collected is used for the following purposes:
<br/><br/>
Processing affiliate applications and payments<br/>
Communicating with affiliates about the program, promotions, and updates<br/>
Monitoring and analyzing affiliate performance<br/>
Complying with legal obligations<br/>

<br/><br/>
4. Information Sharing
We do not sell or share your personal information with third parties, except as necessary to process payments, comply with legal obligations, or as otherwise stated in this policy.
<br/><br/>
5. Security
Elexdon Digital Academy takes reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no security measures are perfect, and we cannot guarantee absolute security.
<br/><br/>
6. Data Retention
We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
<br/><br/>
7. Your Rights
You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us at [contact information].
<br/><br/>
8. Changes to this Policy
We may update this Privacy Policy from time to time. Any changes will be posted on our website, and your continued participation in the affiliate program constitutes acceptance of the updated policy.
<br/><br/>
9. Contact Information
For questions or concerns regarding this Privacy Policy, please contact us at [contact email/phone number].</p>
                    </div>
                </div>
            )}

<div className="faq-section">
    <h2>Affiliate FAQ</h2>
    <div className="faq-item">
        <h3>What qualifications are needed to become an affiliate?</h3>
        <p>There are no specific qualifications required to become an affiliate. However, having a platform with a relevant audience and a good understanding of digital marketing can be beneficial.</p>
    </div>
    <div className="faq-item">
        <h3>What is the application process like?</h3>
        <p>To join our affiliate program, submit your application through our website.</p>
    </div>
    <div className="faq-item">
        <h3>How are affiliates compensated?</h3>
        <p>Affiliates are compensated based on a commission structure. You'll earn a percentage of the sales generated through your unique affiliate links or code.</p>
    </div>
</div>

       
            <div className='CustomBackButtonWrap'>
                <button onClick={()=>window.history.back()}><FaBackward/> Back</button>
            </div>

            
        </div>
        </div>
    );
};

export default BecomeAffiliatePage;
