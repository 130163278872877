import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import cp1 from "../Images/cp1.jpeg";
import cp2 from "../Images/cp2.jpeg";
import cp3 from "../Images/cp3.jpeg";
import cp4 from "../Images/cp4.jpeg";
import cp5 from "../Images/cp5.jpeg";
import cp6 from "../Images/cp6.jpeg";
import cp7 from "../Images/cp7.jpeg";
import cp8 from "../Images/cp8.jpeg";
import cakePic from "../Images/cakeAndDecoration.png"
import {useLocation} from "react-router-dom"
import axios from 'axios';
import Swal from 'sweetalert2';


export const Context = createContext()

const ContextProvider = ({children}) => {
  
    const [loading,setLoading]=useState(false)
    const [adminMenu,setAdminMenu]=useState(0)


//remote url on the main domain
const signupUrl='https://elexdondigitalacademy.com/api/signup.php'
const loginUrl = 'https://elexdondigitalacademy.com/api/login.php'
const emailVerifyUrl = 'https://elexdondigitalacademy.com/api/verify.php'
const forgotPasswordUrl="https://elexdondigitalacademy.com/api/forgotpassword.php"
const resetPasswordUrl= "https://elexdondigitalacademy.com/api/resetpassword.php"
const orderListPostUrl= "https://elexdondigitalacademy.com/api/save_order.php"
const orderListGetUrl="https://elexdondigitalacademy.com/api/fetch_orders.php"
const orderSendEmailUrl="https://elexdondigitalacademy.com/api/send_order_email.php"
const confirmPaymentUrl="https://elexdondigitalacademy.com/api/confirm_paystack_payment.php"
const contactFormUrl = "https://elexdondigitalacademy.com/api/contact_form.php"
const instructorRegistrationUrl = 'https://elexdondigitalacademy.com/api/instructor_registration.php'
const affiliateRegistrationUrl = "https://elexdondigitalacademy.com/api/affiliate_registration.php"
const getAllUsersUrl = "https://elexdondigitalacademy.com/api/get_users.php"
const getAllAffiliatesUrl = "https://elexdondigitalacademy.com/api/getAffiliates.php"
const AuthorSignupUrl = "https://elexdondigitalacademy.com/api/author_signup.php"
const AuthorEmailVerifyUrl = "https://elexdondigitalacademy.com/api/author_verify_email.php"
const AuthorLoginUrl = "https://elexdondigitalacademy.com/api/author_login.php"
const AuthorUpdatePhoneUrl='https://elexdondigitalacademy.com/api/author_update_phone.php'
const affiliateRegistrationUrl2='https://elexdondigitalacademy.com/api/affiliate_signup.php'


//switch for dashboard pages
const [activePage, setActivePage] = useState('profile');


//handling all search
const [searchInput,setSearchInput]=useState("")
const [searchResult,setSearchResult]=useState([])
const [navigateState,setNavigateState]=useState(false)

  // const handleSearch = () => {
  //   if(searchInput.length>0){
  //     // const updatedArray = courses.filter(
  //     //   (property) =>
  //     //     property.title.toLowerCase().includes(searchInput.toLowerCase())
  //     // );


  //     setSearchResult(updatedArray);
  //     setNavigateState(!navigateState);
  //     setSearchInput("")
      
  //   }
  // };


   // Function to fetch courses by search term
   const handleSearch = async () => {
    // Display loading spinner with Swal
    Swal.fire({
      title: 'Searching...',
      text: 'Please wait while we fetch the courses.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await fetch(`https://elexdondigitalacademy.com/api/get_courses_by_name.php?searchTerm=${encodeURIComponent(searchInput)}`);
      
      const data = await response.json();
      
      // Check if the API request was successful
      if (data.success) {
        setSearchResult(data.courses); // Set the fetched courses to state
       
      setNavigateState(!navigateState);
      setSearchInput("")
        
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Courses found successfully.',
          timer: 2000,
          showConfirmButton: false
        });
      } else {
        // Handle error if the request fails
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: data.error || 'An error occurred while fetching courses.',
          confirmButtonText: 'Okay'
        });
      }
    } catch (error) {
      // Handle any other errors (e.g., network issues)
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: 'Something went wrong. Please try again later.',
        confirmButtonText: 'Okay'
      });
    }
  };


  const [selectedCourse, setSelectedCourse] = useState(null);

  const [reRender,setRerender]=useState(true)

//geting all courses
  const [courses,setCourses]=useState([])
  const getAllCourses = async () => {
    try {
        const response = await axios.get("https://elexdondigitalacademy.com/api/get_all_courses.php");
        // console.log(response.data);
        setCourses(response.data.courses)
    } catch (error) {
        // console.error(error);
    }
};

useEffect(() => {
    getAllCourses();
}, [selectedCourse,reRender]);


//get one course
const getCourseById = async (courseId) => {
  try {
      const response = await axios.get(`https://elexdondigitalacademy.com/api/get_course.php?id=${courseId}`);
      // console.log(response.data);
  } catch (error) {
      // console.error(error);
  }
};

// Example usage:
useEffect(() => {
    getCourseById('CRS202408258452');
}, []);



const [test,setTest]=useState(false)
// console.log(test,"test")


//updating affiliate account
const updateAffiliateAccount = async(referrerCode,orderAmount)=>{
  const loadingAlert = Swal.fire({text:"Updating affiliate account..."});
  Swal.showLoading();

  const updateDetail = ({
      referralCode:referrerCode,
      referralAmount:orderAmount*20/100
  });

  try{
    const response = await axios.post('', updateDetail)

   if(response.data.status==="success"){
    Swal.fire({text:"Affiliate Account updated",showConfirmButton:false,timer:2000})
    console.log(response.data)
   }

  }catch(error){
    console.error(error)
  }finally{
    loadingAlert.close();
  }
}

  return (
    <Context.Provider value={{loading,setLoading,signupUrl,emailVerifyUrl,loginUrl,
    activePage, setActivePage,courses,handleSearch,searchResult,searchInput,setSearchInput,navigateState,
    setNavigateState,forgotPasswordUrl,resetPasswordUrl,orderSendEmailUrl,orderListPostUrl,orderListGetUrl,
    confirmPaymentUrl,contactFormUrl,instructorRegistrationUrl,affiliateRegistrationUrl,
    getAllUsersUrl,getAllAffiliatesUrl,setCourses,selectedCourse,setSelectedCourse,getAllCourses,
    test,setTest,reRender,setRerender,adminMenu,setAdminMenu,AuthorSignupUrl,AuthorEmailVerifyUrl,
    AuthorLoginUrl,AuthorUpdatePhoneUrl,affiliateRegistrationUrl2 , updateAffiliateAccount}}>
      {children}
    </Context.Provider>
  )
}

export default ContextProvider




// BACKENDUSEREMAIL=backenddbelexdonacademy@yahoo.com
// BACKENDUSERPASSWORD=#Bdea123bdea
// BACKEND=https://dashboard.render.com/
// DATABASE=https://cloud.mongodb.com/


//paypay and binance implementation
// https://chatgpt.com/c/0fa10eb1-bdbc-4305-afb6-52f5860781c5




//elexdon database password = mikeconnect@2024
// User “elexdond_elexdond” was added to the database “elexdond_academy”.

//elexdon paystack keys //key: "pk_live_3626fe7772aaca28a10724ebb1f9727dfcc5d6cb",
//sk_live_99d792e7e76bf51ca27d7798bb41d2e3204a656d

//hadassah test paystack key
//sk_test_2c7610bd616abcaeaf6a002ad8d96f994d8ff723




// const courses = [
//   {
//     id: "CRS2024071103",
//     title: "FRONTEND WEB DESIGN AND DEVELOPMENT",
//     author:"Elexdon Digital Academy",
//     price: 120000,
//     imgSrc: cp3,
//     type: "Video Course",
//     url: "https://example.com/front-end-development",
//     description: "Develop comprehensive skills in HTML, CSS, and JavaScript to build interactive and visually appealing front-end applications. This course covers the latest techniques in front-end web development, including responsive design, CSS frameworks, and JavaScript libraries like React. You'll learn how to create dynamic web pages that respond to user interactions and integrate APIs to fetch data. The course also emphasizes best practices in coding and design, preparing you to build professional-grade web applications.",
//     outline: [
//       "HTML5 Overview",
//       "Advanced CSS Techniques",
//       "JavaScript ES6 Features",
//       "Responsive Design with CSS Grid and Flexbox",
//       "Introduction to React",
//       "State Management in React",
//       "Building Single Page Applications",
//       "Working with APIs",
//       "Version Control with Git and GitHub",
//       "Capstone Project: Full-Stack Web Application"
//     ]
//   },
//   {
//     id: "CRS2024071104",
//     title: "BACKEND WEB DESIGN AND DEVELOPMENT",
//     author:"Elexdon Digital Academy",
//     price: 120000,
//     imgSrc: cp4,
//     type: "Video Course",
//     url: "https://example.com/back-end-development",
//     description: "Learn back-end technologies including Node.js, Express, and MongoDB to build robust and scalable server-side applications. This course covers essential topics such as server setup, database integration, and API development. You'll explore RESTful API design principles, authentication and authorization mechanisms, and how to handle asynchronous operations. The course also includes best practices for securing and optimizing back-end systems. By the end, you'll be able to design, develop, and deploy back-end solutions for web applications.",
//     outline: [
//       "Introduction to Backend Development",
//       "Node.js Fundamentals",
//       "Express.js Framework",
//       "Working with Databases: MongoDB",
//       "RESTful API Design",
//       "Authentication and Authorization",
//       "Error Handling and Logging",
//       "Deployment and DevOps",
//       "Real-Time Communication with WebSockets",
//       "Final Project: Building a RESTful API"
//     ]
//   },
//   {
//     id: "CRS2024071105",
//     title: "DIGITAL MARKETING",
//     author:"Elexdon Digital Academy",
//     price: 80000,
//     imgSrc: cp5,
//     type: "Text Course",
//     url: "https://example.com/digital-marketing",
//     description: "Explore various digital marketing strategies including SEO, SEM, content marketing, and social media marketing. This course provides a comprehensive overview of how to effectively promote products and services online. You'll learn how to optimize websites for search engines, create engaging content, and leverage social media platforms to reach target audiences. Additionally, the course covers data analytics and reporting tools to measure and improve marketing performance. By the end, you'll be equipped with the skills to develop and execute successful digital marketing campaigns.",
//     outline: [
//       "Introduction to Digital Marketing",
//       "SEO Strategies and Techniques",
//       "Content Marketing Essentials",
//       "Social Media Marketing",
//       "Email Marketing Campaigns",
//       "Pay-Per-Click (PPC) Advertising",
//       "Analytics and Data-Driven Marketing",
//       "Affiliate Marketing",
//       "Influencer Marketing",
//       "Final Project: Developing a Digital Marketing Plan"
//     ]
//   },
//   {
//     id: "CRS2024071106",
//     title: "SEARCH ENGINE OPTIMIZATION",
//     author:"Elexdon Digital Academy",
//     price: 20000,
//     imgSrc: cp6,
//     type: "Video Course",
//     url: "https://example.com/search-engine-optimization",
//     description: "Understand the principles of SEO and learn how to improve website rankings on search engines. This course covers essential SEO strategies including keyword research, on-page optimization, and link building. You'll also learn how to use SEO tools and techniques to analyze and enhance website performance. The course emphasizes white-hat SEO practices and how to avoid common pitfalls. By the end, you'll have the knowledge to increase organic traffic and improve search engine visibility for any website.",
//     outline: [
//       "Introduction to SEO",
//       "Keyword Research and Analysis",
//       "On-Page SEO Best Practices",
//       "Off-Page SEO and Link Building",
//       "Technical SEO Fundamentals",
//       "Content SEO Strategies",
//       "Local SEO Techniques",
//       "SEO Tools and Software",
//       "Measuring SEO Success",
//       "Final Project: SEO Audit and Strategy"
//     ]
//   },
//   {
//     id: "CRS2024071107",
//     title: "DATA SCIENCE",
//     author:"Elexdon Digital Academy",
//     price: 200000,
//     imgSrc: cp7,
//     type: "Text Course",
//     url: "https://example.com/data-science",
//     description: "Gain expertise in data analysis, visualization, and machine learning using Python. This course covers the essential concepts and tools needed for data science, including data manipulation with Pandas, data visualization with Matplotlib, and statistical analysis. You'll also explore machine learning basics and how to implement simple algorithms. The course includes practical projects and case studies to apply your skills in real-world scenarios. By the end, you'll be able to analyze complex datasets and extract valuable insights.",
//     outline: [
//       "Introduction to Data Science",
//       "Data Analysis with Pandas",
//       "Data Visualization with Matplotlib and Seaborn",
//       "Statistical Analysis and Hypothesis Testing",
//       "Machine Learning Basics",
//       "Supervised Learning Algorithms",
//       "Unsupervised Learning Techniques",
//       "Working with Large Datasets",
//       "Data Cleaning and Preprocessing",
//       "Final Project: Data Science Case Study"
//     ]
//   },
//   {
//     id: "CRS2024071108",
//     title: "MACHINE LEARNING WITH PYTHON",
//     author:"Elexdon Digital Academy",
//     price: 350000,
//     imgSrc: cp8,
//     type: "Video Course",
//     url: "https://example.com/machine-learning",
//     description: "Learn the fundamentals of machine learning algorithms and their applications. This course introduces key concepts such as supervised and unsupervised learning, model evaluation, and feature engineering. You'll explore different types of algorithms including decision trees, neural networks, and clustering techniques. The course emphasizes hands-on learning with practical implementations using Python libraries like Scikit-learn and TensorFlow. By the end, you'll be able to build and deploy machine learning models for various applications.",
//     outline: [
//       "Introduction to Machine Learning",
//       "Supervised Learning: Classification and Regression",
//       "Unsupervised Learning: Clustering and Association",
//       "Model Evaluation and Validation",
//       "Feature Engineering and Selection",
//       "Neural Networks and Deep Learning",
//       "Natural Language Processing",
//       "Model Deployment and Scaling",
//       "Ethics in Machine Learning",
//       "Final Project: Building a Machine Learning Model"
//     ]
//   },
  
//   {
//     id: "CRS2024071110",
//     title: "MOBILE APP DEVELOPMENT WITH REACT NATIVE",
//     author:"Elexdon Digital Academy",
//     price: 200000,
//     imgSrc: cp2,
//     type: "Text Course",
//     url: "https://example.com/mobile-application",
//     description: "Develop skills to build mobile applications for Android and iOS platforms using React Native. This course covers the basics of React Native, including setting up your development environment, creating components, and navigating between screens. You'll also learn how to manage state, handle user inputs, and integrate APIs. The course emphasizes cross-platform development, allowing you to write code once and deploy it on both Android and iOS devices. By the end, you'll have the skills to build and deploy mobile apps.",
//     outline: [
//       "Introduction to Mobile Development",
//       "Getting Started with React Native",
//       "Building UI Components",
//       "State Management with Redux",
//       "Navigation in React Native",
//       "Handling User Inputs and Forms",
//       "Connecting to APIs",
//       "Working with Native Modules",
//       "Deploying Apps to App Stores",
//       "Final Project: Developing a Cross-Platform App"
//     ]
//   },
//   {
//     id: "CRS2024071111",
//     title: "UI/UX DESIGN",
//     author:"Elexdon Digital Academy",
//     price: 100000,
//     imgSrc: cp3,
//     type: "Video Course",
//     url: "https://example.com/uiux-design",
//     description: "Learn the principles of UI/UX design to create user-friendly and visually appealing interfaces. This course covers the fundamentals of user interface (UI) design and user experience (UX) research. You'll learn how to conduct user research, create wireframes and prototypes, and test designs for usability. The course also explores visual design principles, including color theory, typography, and layout. By the end, you'll be able to design intuitive interfaces that enhance the user experience.",
//     outline: [
//       "Introduction to UI/UX Design",
//       "User Research Methods",
//       "Creating User Personas",
//       "Wireframing and Prototyping",
//       "Visual Design Principles",
//       "Color Theory and Typography",
//       "Usability Testing and Feedback",
//       "Design Systems and Style Guides",
//       "Designing for Accessibility",
//       "Final Project: Complete UI/UX Design"
//     ]
//   },
//   {
//     id: "CRS2024071112",
//     title: "DATA ANALYSIS WITH POWER BI",
//     author:"Elexdon Digital Academy",
//     price: 80000,
//     imgSrc: cp4,
//     type: "Video Course",
//     url: "https://example.com/data-analysis-power-bi",
//     description: "This comprehensive course on Data Analysis with Power BI is designed for beginners and intermediate users who want to harness the power of this popular data visualization tool. You'll start with the basics of Power BI, learning how to connect to various data sources, perform data cleaning, and transform data to make it analysis-ready. The course covers the creation of interactive dashboards, custom visuals, and DAX (Data Analysis Expressions) for advanced data manipulation. Additionally, you'll learn best practices for data storytelling and sharing insights with stakeholders. By the end of this course, you'll be equipped to turn raw data into actionable insights, enabling data-driven decision-making.",
//     outline: [
//       "Introduction to Power BI and Its Interface",
//       "Connecting to Data Sources",
//       "Data Cleaning and Transformation",
//       "Creating and Customizing Visuals",
//       "Using DAX for Advanced Calculations",
//       "Building Interactive Dashboards",
//       "Data Storytelling Techniques",
//       "Sharing Reports and Dashboards",
//       "Power BI Service and Collaboration",
//       "Final Project: Comprehensive Data Analysis Report"
//     ]
//   },
  
//   {
//     id: "CRS2024071113",
//     title: "BASIC / FUNDAMENTALS OF BLOGGING WITH (CMS AND FREE .COM.NG DOMAIN)",
//     author:"Elexdon Digital Academy",
//     price: 100000,
//     imgSrc: cp5,
//     type: "Text Course",
//     url: "https://example.com/blogging",
//     description: "Learn how to create, manage, and monetize a successful blog. This course covers the basics of setting up a blog using various content management systems (CMS), including WordPress. You'll learn how to create engaging content, optimize posts for SEO, and use social media to drive traffic. The course also covers strategies for monetizing your blog through ads, affiliate marketing, and sponsored content. By the end, you'll have the skills to run a professional blog and grow your online presence.",
//     outline: [
//       "Introduction to Blogging",
//       "Choosing the Right CMS",
//       "Setting Up Your Blog",
//       "Content Creation and Planning",
//       "SEO for Bloggers",
//       "Social Media Strategies for Bloggers",
//       "Monetization Strategies",
//       "Email Marketing for Bloggers",
//       "Analyzing Blog Performance",
//       "Final Project: Launching Your Blog"
//     ]
//   },
//   {
//     id: "CRS2024071114",
//     title: "GRAPHIC DESIGN (Ps,CR,Ai)",
//     author:"Elexdon Digital Academy",
//     price: 80000,
//     imgSrc: cp6,
//     type: "Video Course",
//     url: "https://example.com/graphic-design",
//     description: "Develop graphic design skills using tools like Photoshop, Illustrator, and CorelDRAW. This course covers the principles of design, including color theory, typography, and composition. You'll learn how to create stunning graphics for both digital and print media. The course also includes practical projects where you'll design logos, brochures, and social media graphics. By the end, you'll have a strong portfolio of work and the skills to pursue a career in graphic design.",
//     outline: [
//       "Introduction to Graphic Design",
//       "Understanding Design Principles",
//       "Photoshop Basics and Tools",
//       "Illustrator Basics and Tools",
//       "CorelDRAW Essentials",
//       "Color Theory and Application",
//       "Typography and Text Design",
//       "Creating Digital and Print Graphics",
//       "Portfolio Development",
//       "Final Project: Graphic Design Portfolio"
//     ]
//   },
//   {
//     id: "CRS2024071115",
//     title: "FULL STACK WEB DEVELOPMENT",
//     author:"Elexdon Digital Academy",
//     price: 250000,
//     imgSrc: cp7,
//     type: "Text Course",
//     url: "https://example.com/full-stack-development",
//     description: "Become a full stack developer by mastering both front-end and back-end technologies. This comprehensive course covers everything from HTML, CSS, and JavaScript on the front end, to server-side technologies like Node.js, Express, and databases on the back end. You'll learn how to integrate different technologies to create complete web applications. The course also emphasizes best practices in coding, testing, and deployment. By the end, you'll be equipped to build, deploy, and maintain full-stack web applications.",
//     outline: [
//       "Front-End Development: HTML, CSS, JavaScript",
//       "Advanced JavaScript and Frameworks",
//       "Backend Development with Node.js",
//       "Building RESTful APIs",
//       "Database Management with MongoDB and SQL",
//       "Authentication and Authorization",
//       "Version Control with Git",
//       "Deployment and DevOps",
//       "Testing and Debugging",
//       "Final Project: Full-Stack Application"
//     ]
//   },
//   {
//     id: "CRS2024071116",
//     title: "CONTENT CREATION AND MANAGEMENT",
//     author:"Elexdon Digital Academy",
//     price: 25000,
//     imgSrc: cp8,
//     type: "Video Course",
//     url: "https://example.com/content-creation-management",
//     description: "Learn how to create and manage content for various digital platforms. This course covers content strategy, writing, and editing for blogs, websites, and social media. You'll learn how to use different content management systems (CMS) and tools to streamline content creation. The course also includes best practices for SEO and content marketing. By the end, you'll have the skills to produce high-quality content that engages audiences and supports business goals.",
//     outline: [
//       "Introduction to Content Creation",
//       "Developing a Content Strategy",
//       "Writing and Editing for Digital Media",
//       "Using Content Management Systems (CMS)",
//       "SEO Best Practices for Content",
//       "Content Marketing Strategies",
//       "Visual Content Creation",
//       "Content Distribution Channels",
//       "Measuring Content Performance",
//       "Final Project: Content Marketing Plan"
//     ]
//   },
//   {
//     id: "CRS2024071117",
//     title: "SOCIAL MEDIA MANAGEMENT",
//     author:"Elexdon Digital Academy",
//     price: 25000,
//     imgSrc: cp1,
//     type: "Video Course",
//     url: "https://example.com/social-media-management",
//     description: "Master the art of managing social media accounts and growing online presence. This course covers the fundamentals of social media marketing, including platform-specific strategies for Facebook, Instagram, Twitter, and LinkedIn. You'll learn how to create engaging content, manage social media campaigns, and analyze metrics to improve performance. The course also includes best practices for social media advertising and community management. By the end, you'll be equipped to build and execute effective social media strategies.",
//     outline: [
//       "Introduction to Social Media Management",
//       "Understanding Different Social Media Platforms",
//       "Creating Engaging Social Media Content",
//       "Social Media Campaign Planning",
//       "Social Media Analytics and Insights",
//       "Social Media Advertising Strategies",
//       "Community Management and Engagement",
//       "Building a Social Media Brand",
//       "Crisis Management on Social Media",
//       "Final Project: Social Media Marketing Plan"
//     ]
//   },
//   {
//     id: "CRS2024071118",
//     title: "CYBER SECURITY",
//     author:"Elexdon Digital Academy",
//     price: 400000,
//     imgSrc: cp2,
//     type: "Video Course",
//     url: "https://example.com/cyber-security",
//     description: "Learn about cyber security threats and how to protect systems against them. This course covers the fundamentals of cyber security, including understanding different types of cyber threats, network security measures, and secure software development practices. You'll learn how to implement security protocols, conduct risk assessments, and respond to security incidents. The course also covers the latest trends and technologies in cyber security. By the end, you'll be prepared to safeguard digital assets and respond effectively to cyber threats.",
//     outline: [
//       "Introduction to Cyber Security",
//       "Understanding Cyber Threats",
//       "Network Security Fundamentals",
//       "Secure Software Development",
//       "Implementing Security Protocols",
//       "Conducting Risk Assessments",
//       "Incident Response and Management",
//       "Cyber Security Tools and Technologies",
//       "Legal and Ethical Considerations",
//       "Final Project: Cyber Security Strategy"
//     ]
//   },
//   {
//     id: "CRS2024071119",
//     title: "BASIC / FUNDAMENTAL DATA ANALYSIS WITH EXCEL",
//     author:"Elexdon Digital Academy",
//     price: 80000,
//     imgSrc: cp3,
//     type: "Video Course",
//     url: "https://example.com/data-analysis-excel",
//     description: "Learn the basics of data analysis using Microsoft Excel. This course covers the essential features of Excel, including data entry, formula creation, and data visualization. You'll learn how to clean and prepare data, use pivot tables, and create charts and graphs to present your findings. The course also covers advanced Excel functions and data analysis techniques. By the end, you'll have the skills to perform comprehensive data analysis and make data-driven decisions using Excel.",
//     outline: [
//       "Introduction to Excel",
//       "Data Entry and Formatting",
//       "Basic Formulas and Functions",
//       "Data Cleaning and Preparation",
//       "Using Pivot Tables",
//       "Creating Charts and Graphs",
//       "Advanced Excel Functions",
//       "Data Analysis Techniques",
//       "Presenting Data Insights",
//       "Final Project: Excel Data Analysis"
//     ]
//   },
//   {
//     id: "CRS2024071120",
//     title: "BASIC / FUNDAMENTAL DATA ANALYSIS WITH SQL AND RELATIONAL DATABASE",
//     author:"Elexdon Digital Academy",
//     price: 100000,
//     imgSrc: cp4,
//     type: "Video Course",
//     url: "https://example.com/data-analysis-sql",
//     description: "Understand the fundamentals of data analysis using SQL and relational databases. This course covers the basics of SQL syntax, database design, and data manipulation. You'll learn how to write complex queries to retrieve and analyze data from relational databases. The course also covers data aggregation, indexing, and performance optimization. By the end, you'll have the skills to perform efficient data analysis and manage databases using SQL.",
//     outline: [
//       "Introduction to SQL",
//       "Database Design Fundamentals",
//       "Basic SQL Queries",
//       "Advanced Query Techniques",
//       "Data Aggregation and Grouping",
//       "Indexing and Optimization",
//       "Working with Joins",
//       "Data Integrity and Constraints",
//       "Database Security and Permissions",
//       "Final Project: SQL Data Analysis"
//     ]
//   },
//   {
//     id: "CRS2024071121",
//     title: "BASIC / FUNDAMENTAL DATA ANALYSIS WITH PYTHON",
//     author:"Elexdon Digital Academy",
//     price: 120000,
//     imgSrc: cp5,
//     type: "Video Course",
//     url: "https://example.com/data-analysis-python",
//     description: "Learn data analysis using Python and its libraries. This course covers Python basics, including syntax and data structures, before moving into data analysis with libraries like Pandas and Matplotlib. You'll learn how to clean and manipulate data, visualize trends, and perform statistical analysis. The course also covers best practices for data analysis and the use of Jupyter notebooks for project documentation. By the end, you'll be able to use Python to conduct comprehensive data analysis and create meaningful insights.",
//     outline: [
//       "Introduction to Python Programming",
//       "Working with Python Data Structures",
//       "Data Analysis with Pandas",
//       "Data Visualization with Matplotlib",
//       "Data Cleaning Techniques",
//       "Statistical Analysis with Python",
//       "Working with Jupyter Notebooks",
//       "Machine Learning Basics",
//       "Project Documentation and Reporting",
//       "Final Project: Python Data Analysis"
//     ]
//   },
//   {
//     id: "CRS2024071122",
//     title: "BASIC / FUNDAMENTAL DATA ANALYSIS WITH SPSS",
//     author:"Elexdon Digital Academy",
//     price: 70000,
//     imgSrc: cp6,
//     type: "Video Course",
//     url: "https://example.com/data-analysis-spss",
//     description: "Learn data analysis using SPSS software. This course covers the fundamentals of SPSS, including data entry, descriptive statistics, and data visualization. You'll learn how to use SPSS to conduct hypothesis testing, regression analysis, and other statistical methods. The course also covers best practices for data analysis and reporting. By the end, you'll have the skills to perform comprehensive data analysis using SPSS and interpret the results effectively.",
//     outline: [
//       "Introduction to SPSS",
//       "Data Entry and Management",
//       "Descriptive Statistics in SPSS",
//       "Data Visualization Techniques",
//       "Hypothesis Testing with SPSS",
//       "Regression Analysis",
//       "Advanced Statistical Methods",
//       "Data Cleaning and Preparation",
//       "Reporting and Interpreting Results",
//       "Final Project: SPSS Data Analysis"
//     ]
//   },
//   {
//     id: "CRS2024071123",
//     title: "PROJECT MANAGEMENT",
//     author:"Elexdon Digital Academy",
//     price: 40000,
//     imgSrc: cp5,
//     type: "Video Course",
//     url: "https://example.com/project-management",
//     description: `The Project Management Professional (PMP) Certification Training is a comprehensive program that equips participants with the knowledge, skills, and best practices essential for successful project management. This course is specifically designed to prepare individuals for the PMP certification examination, a globally recognized standard for project management excellence.`,
//     outline: [
//       "Mastering Project Management Framework: Understand and apply the key principles, concepts, and processes outlined in the Project Management Body of Knowledge (PMBOK® Guide).",
//       "Project Integration Management: Learn to effectively integrate various project management processes to ensure project success from initiation to closure.",
//       "Scope Management: Develop skills in defining, verifying, and controlling project scope to meet stakeholders' requirements.",
//       "Time Management: Explore techniques for creating realistic project schedules, sequencing activities, and managing project timelines.",
//       "Cost Management: Acquire knowledge in estimating costs, budgeting, and controlling project finances to ensure project profitability.",
//       "Quality Management: Learn to implement quality management processes and tools to meet project objectives and stakeholder expectations.",
//       "Human Resource Management: Understand the principles of team building, leadership, and communication to effectively manage project teams.",
//       "Communications Management: Develop effective communication plans and strategies to keep stakeholders informed and engaged throughout the project lifecycle.",
//       "Risk Management: Identify, analyze, and respond to project risks to minimize uncertainties and enhance project success.",
//       "Procurement Management: Gain insights into procurement processes, contract management, and vendor relationships.",
//       "Stakeholder Management: Learn strategies for identifying and managing stakeholders to ensure project success and stakeholder satisfaction.",
//       "Getting started with Project Management",
//       "Project Management and the Organization",
//       "Initiating a project",
//       "Planning for Project Time Management",
//       "Planning Project Budget, Quality, and Communications",
//       "Planning for Risk, Procurements, and Stakeholder Management",
//       "Executing a project",
//       "Managing Project Work, Scope, Schedules, and Cost",
//       "Monitoring and controlling projects",
//       "Closing a Project"
//   ]
  
//   },
  
//   {
//     id: "CRS2024071124",
//     title: "ADVANCE EXCEL",
//     author:"Elexdon Digital Academy",
//     price: 40000,
//     imgSrc: cp6,
//     type: "Video Course",
//     url: "https://example.com/advanced-excel",
//     description: "Master advanced Excel techniques to boost productivity and data analysis capabilities. This course covers advanced Excel functions, including complex formulas, pivot tables, and data analysis tools like Power Query and Power Pivot. You'll learn how to automate tasks with macros, perform data modeling, and create interactive dashboards. The course also includes best practices for data visualization and reporting. By the end, you'll be equipped to use Excel to its full potential in professional settings.",
//     outline: [
//       "Advanced Formulas and Functions",
//       "Working with Pivot Tables",
//       "Data Analysis with Power Query",
//       "Data Modeling with Power Pivot",
//       "Creating Interactive Dashboards",
//       "Automating Tasks with Macros",
//       "Data Visualization Techniques",
//       "Advanced Data Analysis Tools",
//       "Excel Best Practices",
//       "Final Project: Excel Data Analysis and Reporting"
//     ]
//   },
//   {  
//     id: "CRS2024071125",
//     title: "CMS WEB SPECIALIST: WORDPRESS WEB DESIGN",
//     author:"Elexdon Digital Academy",
//     price: 80000,
//     imgSrc: cp7,
//     type: "Video Course",
//     url: "https://example.com/wordpress-web-design",
//     description: "Specialize in WordPress web design and development. This course covers advanced WordPress techniques, including theme customization, plugin development, and e-commerce integration. You'll learn how to create custom WordPress themes from scratch, optimize sites for performance, and ensure your website is secure. The course also explores advanced topics such as managing multisite installations and using WordPress for complex projects. By the end, you'll be a WordPress specialist capable of building and managing sophisticated websites.",
//     outline: [
//       "Advanced WordPress Techniques",
//       "Theme Customization and Development",
//       "Building Custom Plugins",
//       "E-commerce Integration with WooCommerce",
//       "Optimizing WordPress Performance",
//       "WordPress Security Best Practices",
//       "Managing Multisite Installations",
//       "Using WordPress for Complex Projects",
//       "SEO for WordPress Sites",
//       "Final Project: WordPress Website Development"
//     ]
//   },
//   {
//     id: "CRS2024071126",
//     title: "BASIC VIDEO EDITING",
//     author:"Elexdon Digital Academy",
//     price: 35000,
//     imgSrc: cp8,
//     type: "Video Course",
//     url: "https://example.com/basic-video-editing",
//     description: "Learn the basics of video editing to create professional-looking videos. This course covers video editing software, including Adobe Premiere Pro and Final Cut Pro. You'll learn how to import and organize footage, apply transitions and effects, and edit audio tracks. The course also covers color correction, video export settings, and best practices for online video content. By the end, you'll have the skills to edit videos for various purposes, from social media to professional projects.",
//     outline: [
//       "Introduction to Video Editing",
//       "Understanding Video Formats",
//       "Importing and Organizing Footage",
//       "Basic Editing Techniques",
//       "Applying Transitions and Effects",
//       "Editing Audio Tracks",
//       "Color Correction and Grading",
//       "Exporting Video Projects",
//       "Best Practices for Online Video",
//       "Final Project: Video Editing Project"
//     ]
//   },
//   {
//     "id": "CRS2024071127",
//     "title": "MICROSOFT OFFICE SUITE",
//     author:"Elexdon Digital Academy",
//     "price": 45000,
//     "imgSrc": cp1,
//     "type": "Video Course",
//     "url": "https://example.com/microsoft-office-suite",
//     "description": "The Microsoft Office Suite course offers a thorough introduction to the key applications within Microsoft Office, including Word, Excel, PowerPoint, and Outlook. This course is perfect for beginners and intermediate users looking to enhance their skills and efficiency. You will learn the fundamentals of each application, such as creating and formatting documents in Word, building and analyzing spreadsheets in Excel, designing compelling presentations in PowerPoint, and managing emails and calendars in Outlook. The course also covers advanced features and tips for streamlining workflows and improving productivity. By the end of the course, you'll be proficient in using Microsoft Office tools for both personal and professional tasks.",
//     "outline": [
//       "Introduction to Microsoft Office Suite",
//       "Getting Started with Microsoft Word",
//       "Creating and Formatting Documents in Word",
//       "Excel Basics: Building and Organizing Spreadsheets",
//       "Advanced Excel Functions and Data Analysis",
//       "Designing Effective Presentations with PowerPoint",
//       "Using Graphics and Animations in PowerPoint",
//       "Managing Emails and Calendars in Outlook",
//       "Streamlining Workflows and Productivity Tips",
//       "Final Project: Comprehensive Office Suite Project"
//     ]
//   },
//   {
//     "id": "CRS2024071128",
//     "title": "CAKE BAKING AND DECORATION",
//     // author:"Elexdon Digital Academy",
//     author:"PrinceWill",
//     "price": 30000,
//     "imgSrc": cakePic,
//     "type": "Video Course",
//     "url": "https://example.com/cake-baking-decoration",
//     "description": "The Cake Baking and Decoration course is designed for aspiring bakers and enthusiasts looking to master the art of cake making and decoration. This course covers essential techniques for baking delicious cakes, as well as advanced decoration skills to create stunning cake designs. You'll start with the basics of cake ingredients, mixing methods, and baking techniques. As the course progresses, you'll learn various frosting and filling techniques, including buttercream, fondant, and ganache. The decoration modules will guide you through the creation of intricate designs using piping, molding, and sculpting techniques. By the end of this course, you will have the skills to bake and decorate cakes for any occasion, from simple birthday cakes to elaborate wedding creations.",
//     "outline": [
//       "Introduction to Cake Baking and Decoration",
//       "Understanding Cake Ingredients and Tools",
//       "Basic Cake Baking Techniques",
//       "Creating Different Cake Flavors and Textures",
//       "Fundamentals of Frosting and Filling",
//       "Buttercream Techniques and Piping Basics",
//       "Working with Fondant: Covering and Decorating",
//       "Creating Flowers and Figures with Fondant",
//       "Advanced Cake Decoration: Sculpting and Molding",
//       "Final Project: Designing a Themed Celebration Cake"
//     ]
// }

  
// ];
  




// local urls
// const signupUrl="http://localhost:5000/api/auth/signup"
// const loginUrl = "http://localhost:5000/api/auth/login"
// const emailVerifyUrl = "http://localhost:5000/api/auth/verify"
// const forgotPasswordUrl="http://localhost:5000/api/auth/forgot-password"
// const resetPasswordUrl= "http://localhost:5000/api/auth/reset-password"
// const orderListPostUrl= "http://localhost:5000/api/order"
// const orderListGetUrl="http://localhost:5000/api/orders"
// const orderSendEmailUrl="http://localhost:5000/api/send-order-summary"
// const confirmPaymentUrl="http://localhost:5000/api/verify-transaction"
// const contactFormUrl = "http://localhost:5000/api/contact"
// const instructorRegistrationUrl = 'http://localhost:5000/api/instructor/register'
// const affiliateRegistrationUrl = "http://localhost:5000/api/affiliate/register"
// const getAllUsersUrl = "http://localhost:5000/api/auth/users"
// const getAllAffiliatesUrl = "http://localhost:5000/api/affiliate/all"



// remote url on render
// const signupUrl="https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/signup"
// const loginUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/login"
// const emailVerifyUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/verify"
// const forgotPasswordUrl="https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/forgot-password"
// const resetPasswordUrl= "https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/reset-password"
// const orderListPostUrl= "https://backendelexdondigitalacademy-cjoj.onrender.com/api/order"
// const orderListGetUrl="https://backendelexdondigitalacademy-cjoj.onrender.com/api/orders"
// const orderSendEmailUrl="https://backendelexdondigitalacademy-cjoj.onrender.com/api/send-order-summary"
// const confirmPaymentUrl="https://backendelexdondigitalacademy-cjoj.onrender.com/api/verify-transaction"
// const contactFormUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/contact"
// const instructorRegistrationUrl = 'https://backendelexdondigitalacademy-cjoj.onrender.com/api/instructor/register'
// const affiliateRegistrationUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/affiliate/register"
// const getAllUsersUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/auth/users"
// const getAllAffiliatesUrl = "https://backendelexdondigitalacademy-cjoj.onrender.com/api/affiliate/all"




//User: elexdondigitalac_elexdondigitalac
// Database: elexdondigitalac_db1 
//myacademy@2024

//elexdon Binance tron wallet : TV5bqv9a4L8kaBnqgvZvyGVBQVNZVCPiwD
//elexdon bic wallet 1 : bc1qj6mrjrcryed67jd6qw2tjfx0xyn3j83pf7e7gt
//elexdon btc wallet 2 (binance): 1EPvkK3ANxZNtR8Ky3FUnuBULvzrZDKpBL
//