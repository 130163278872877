

import React from 'react'
import {useSelector} from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateAffiliateDasboard = () => {
    const affiliateToken = useSelector(state=>state.affiliateToken)
    // const affiliateToken = true

    // console.log(userToken)
  return (
    <div>
      {affiliateToken?<Outlet/>:<Navigate to="/affiliatelogin"/>}
    </div>
  )
}

export default PrivateAffiliateDasboard

