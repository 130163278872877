

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../Features/Slice";
import "../CSS/OrderSummaryPage.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from "./Context";
import PaystackPop from "@paystack/inline-js";
import Swal from "sweetalert2";
import axios from "axios";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import BtcPaymentModal from "./BitcoinPayment";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const OrderSummaryPage = () => {
  const location = useLocation();
  const { activePage, setActivePage, orderSendEmailUrl, orderListPostUrl, confirmPaymentUrl } = useContext(Context);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deliveryDetails = useSelector((state) => state.deliveryDetails);
  const cart = useSelector((state) => state.cart);
  const userInfo = useSelector((state) => state.userInfo);
  const [currencyOption, setCurrencyOption] = useState("");
  const [bitcoinModal,setBitcoinModal]=useState(false);

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      return total + price;
    }, 0);
  };

  const postOrderFunction = async (reference) => {
    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: reference,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => ({
        id: item.id,
        title: `${item.title} by ${item.author} - Course ID:${item.id}`,
        price: item.price
      })),
      total: calculateTotal(),
      currency: "NGN"
    };

    try {
      const response = await axios.post(orderListPostUrl, orderSummary);
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: response.data.message,
        showConfirmButton: false,
        timer: 2000
      });

      dispatch(clearCart());
      navigate("/userdashboard");
      setActivePage('courses');
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: error.response?.data?.error || 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  };

  const sendOrderEmailFunction = async (reference) => {
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
    
    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: reference,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode: userInfo.referrerCode,
      cartItems: cart.map(item => `${item.title} by ${item.author} Course ID:${item.id} - ₦${new Intl.NumberFormat().format(item.price)}`),
      total: `₦${new Intl.NumberFormat().format(calculateTotal())}`
    };

    try {
      const response = await axios.post(orderSendEmailUrl, {
        buyerEmail: deliveryDetails.email,
        sellerEmail: 'info@elexdontech.com',
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Order successful. Please check your email inbox or spam for details." });
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    } finally {
      loadingAlert.close();
    }
  };

  const payWithPaystack = () => {
    const paystack = new PaystackPop();
    paystack.newTransaction({
      // key: "pk_test_60e1f53bba7c80b60029bf611a26a66a9a22d4e4",
      key: "pk_live_3626fe7772aaca28a10724ebb1f9727dfcc5d6cb",
      amount: calculateTotal() * 100,
      email: deliveryDetails.email,
      firstname: deliveryDetails.name,
      onSuccess: (transaction) => {
        postOrderFunction(transaction.reference);
        sendOrderEmailFunction(transaction.reference);
      },
      onCancel: () => {
        Swal.fire({ icon: "error", text: "Payment cancelled.", showConfirmButton: true });
      },
      onError: (error) => {
        Swal.fire({ icon: "error", text: `Payment failed: ${error.message}`, showConfirmButton: true });
      }
    });
  };

  const verifyTransaction = async (reference) => {
    const loadingAlert = Swal.fire({
      title: "Confirming payment",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    try {
      const response = await axios.post(`${confirmPaymentUrl}`, { reference });

      if (response.data.status === 'success') {
        Swal.fire({
          icon: "success",
          text: "Payment successful!",
          showConfirmButton: true,
          timer: 2000
        });

        postOrderFunction(reference);
        sendOrderEmailFunction(reference);
      } else {
        Swal.fire({
          icon: "error",
          text: `${response.data.message}`,
          showConfirmButton: true
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || 'Error verifying payment!',
        showConfirmButton: true
      });
    } finally {
      loadingAlert.close();
    }
  };

  const generateOrderRef = () => `order-${Math.random().toString(36).substring(2)}`;
  const nairaAmount = calculateTotal(); // Get total amount in NGN
  const exchangeRate = 1600; // Sample exchange rate from NGN to USD
  const usdAmount = (nairaAmount / exchangeRate).toFixed(2); // Convert to USD

  const flutterwaveConfig = {
    public_key: "FLWPUBK_TEST-3f4052d4a8b6e027b460756652b193df-X",
    tx_ref: generateOrderRef(),
    amount: usdAmount,
    currency: 'USD',
    payment_options: 'card,banktransfer',
    customer: {
      email: deliveryDetails.email,
      name: deliveryDetails.name,
    },
    customizations: {
      title: 'Heovin.com',
      description: 'Payment for items in cart',
    },
  };

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
  const payWithFlutterWave = () => {
    // Ask for confirmation before proceeding with the payment
    Swal.fire({
      title: "Confirm Payment",
      text: `You are about to pay $${usdAmount} which is (NGN ${nairaAmount}). Do you want to proceed?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with the Flutterwave payment
        Swal.fire({text:"please wait..."})
        Swal.showLoading();

        handleFlutterPayment({
          callback: (response) => {
            if (response.status === "successful") {
              Swal.fire({ icon: "success", text: "Payment successful!" });
              postOrderFunction(response.transaction_id); // Call order processing function
            }
            closePaymentModal();
          },
          onClose: () => Swal.fire({ icon: "error", text: "Payment cancelled." }),
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({ icon: "info", text: "Payment cancelled by user." });
      }
    });
  };



  // const handlePayPalPayment = (details) => {
  //   postOrderFunction(details.id); // Pass the transaction ID to your order function
  //   sendOrderEmailFunction(details.id);
  // };

  // useEffect(() => {
  //   // Load PayPal script
  //   const paypalButtons = document.getElementById("paypal-buttons");
  //   if (paypalButtons) {
  //     window.paypal?.Buttons({
  //       createOrder: (data, actions) => {
  //         return actions.order.create({
  //           purchase_units: [{
  //             amount: {
  //               value: (calculateTotal() / 400).toFixed(2), // Convert NGN to USD
  //             }
  //           }]
  //         });
  //       },
  //       onApprove: (data, actions) => {
  //         return actions.order.capture().then((details) => {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Payment successful",
  //             text: "Transaction ID: " + details.id,
  //           });
  //           handlePayPalPayment(details);
  //         });
  //       },
  //       onCancel: () => {
  //         Swal.fire({
  //           icon: "error",
  //           text: "Payment was cancelled",
  //         });
  //       },
  //       onError: (err) => {
  //         Swal.fire({
  //           icon: "error",
  //           text: "Payment failed: " + err.message,
  //         });
  //       }
  //     }).render(paypalButtons);
  //   }
  // }, [currencyOption]);



   const handlePayPalPayment = (details) => {
    postOrderFunction(details.id); // Pass the transaction ID to your order function
    sendOrderEmailFunction(details.id);
  };

  return (
    <div className="order-summary-page" key={location.pathname}>
      <h2>ORDER SUMMARY</h2>
      <div className="delivery-details">
        <p><span style={{ color: "blue" }}>Name:</span> {deliveryDetails.name}</p>
        <p><span style={{ color: "blue" }}>Email:</span> {deliveryDetails.email}</p>
        <p><span style={{ color: "blue" }}>Phone:</span> {deliveryDetails.phoneNumber}</p>
        <p><span style={{ color: "blue" }}>Referrer Code:</span> {userInfo?.referrerCode}</p>
      </div>
      <div className="cart-details">
        <ul>
          {cart.map((item) => (
            <li key={item.id}>
              <div className="cart-item">
                <img src={`https://elexdondigitalacademy.com/api/uploads/${item.image}`} alt="cartItemImg" />
                <div>
                  <p className="item-name">{item.title}</p>
                  <p style={{ fontSize: "small", color: "gray" }}>Course ID: {item.id}</p>
                  <p style={{ fontSize: "small", color: "gray" }}>{item.author}</p>
                </div>
                <p className="item-price">
                  Price: ₦ {Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <h3 className="cart-total">
          Cart Total: ₦ {Number(calculateTotal()).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </h3>
      </div>

      <select className='OrderSummaryPageSelect' onChange={(e) => setCurrencyOption(e.target.value)}>
        <option value={currencyOption}>--Select Currency in which you want to pay and click "pay now"--</option>
        <option value="naira">Naira</option>
        {/* <option value="usd2">USD (paypal)</option> */}
        <option value="btc">Bitcoin</option>
        <option value="usd1">USD</option>
        
      </select>

      {currencyOption === "naira" && <button type="button" className="pay-now-button" onClick={payWithPaystack}>Pay Now</button>}
      {currencyOption === "usd1" && <button type="button" className="pay-now-button" onClick={payWithFlutterWave}>Pay Now</button>}
      {/* {currencyOption === "usd2" && <button type="button" className="pay-now-button" onClick={handlePayPalPayment}>Pay Now</button>} */}
      
      {currencyOption === "btc" && <button type="button" className="pay-now-button" onClick={()=>setBitcoinModal(true)}>Pay Now</button>}
      {bitcoinModal&&<BtcPaymentModal 
      btcWallet='1EPvkK3ANxZNtR8Ky3FUnuBULvzrZDKpBL'
      totalNgnAmount={calculateTotal()} 
      totalBtcAmount={calculateTotal()*0.0000000089}
      onClose={()=>setBitcoinModal(false)}/>}


        {/* PayPal Payment Section */}
      {currencyOption === "usd2" && (
        <PayPalScriptProvider options={{ "client-id": "AY5FQLO5B8OeDzB8AdI3bJx7gGj8rWqGQe4bY5vOL1KfKzHtUO1I_1zjBrfB6fq1mD0kVIFkS12bP8eG" }}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: usdAmount, // Use USD amount
                  },
                }],
              });
            }}
            onApprove={async (data, actions) => {
              const details = await actions.order.capture();
              Swal.fire({
                icon: "success",
                title: "Payment successful",
                text: "Transaction ID: " + details.id,
              });
              await handlePayPalPayment(details);
            }}
            onCancel={() => {
              Swal.fire({
                icon: "error",
                text: "Payment was cancelled",
              });
            }}
            onError={(err) => {
              Swal.fire({
                icon: "error",
                text: "Payment failed: " + err.message,
              });
            }}
          />
        </PayPalScriptProvider>
      )}


      {/* PayPal Button */}
      {/* {currencyOption === "usd" && (
        <div id="paypal-buttons" className="paypal-buttons"></div>
      )} */}
    </div>
  );
};

export default OrderSummaryPage;

